

.group-view {
  padding: 20px 10px;
  &--heading {
    background: #ffffff;
    border-radius: 5px 5px 0px 0px;
    display: flex;
    justify-content: space-between;
    padding: 12px 10px;
    align-items: center;
    &--title {
      h1 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #000000;
        margin-bottom: 0;
      }
    }
    &-btn {
      button {
        background: #000000;
        border-radius: 5px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        cursor: pointer;
        span {
          margin-right: 10px;
        }
      }
    }
  }
  &--body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 1px;
    margin-top: 25px;
    &--header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 25px;
      gap: 25px;
      border: 0.3px solid #dfdfdf;
      border-radius: 5px 5px 0px 0px;
      .cover {
        width: 1400px;
        height: 175px;
        border: 0.3px solid #dfdfdf;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .avatar {
        width: 100px;
        height: 100px;
        z-index: 15;
        margin-top: -95px;
        margin-left: 30px;
        border: 0.5px solid #dfdfdf;

        img {
          width: 100%;
          height: 100%;
        }
      }
      .profile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0px;
        h3 {
          font-family: "Poppins";
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #666666;
        }
        p {
          display: flex;
          gap: 10px;
          align-items: center;
          padding: 0px;
          font-family: "Poppins";
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #666666;
        }
      }
    }
    &--tag {
      display: flex;
      align-items: flex-start;
      padding: 0px;
      width: 100%;
      margin-top: 25px;
      .admin {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        background-color: #ffffff;
        width: 125px;
        h3 {
          font-family: "Poppins";
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #666666;
        }
      }
      .adminName {
        display: flex;
        align-items: center;
        padding: 25px;
        gap: 25px;
        height: 65px;
        background-color: #f9f9f9;
      }
      .adminTag {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        border: 1px solid #4895ef;
        border-radius: 5px;
        p {
          font-family: "Poppins";
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #4895ef;
          margin: 10px;
        }
      }
    }
    &--about {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      width: 100%;
      margin-top: 25px;
      .about-header {
        display: flex;
        align-items: center;
        padding: 11px 21px 11px 20px;
        height: 45px;
        background-color: #ffffff;
        border: 0.3px solid #dfdfdf;
        width: 100%;
        h3 {
          font-family: "Poppins";
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #666666;
        }
      }
      .about-body {
        display: flex;
        align-items: flex-start;
        padding: 14px 20px;
        width: 100%;
        height: auto;
        background-color: #ffffff;
        border: 0.3px solid #dfdfdf;
        border-radius: 0px 0px 5px 5px;
        p {
          font-family: "Poppins";
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #8c8c8c;
        }
      }
    }
  }
}

.feed-edit {
  padding: 20px 10px;
  margin: 0px 30px;
  &--heading {
    background: #ffffff;
    border-radius: 5px 5px 0px 0px;
    display: flex;
    justify-content: space-between;
    padding: 12px 10px;
    align-items: center;
    &--title {
      h1 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #000000;
        margin-bottom: 0;
      }
    }
    &-btn {
      button {
        background: #000000;
        border-radius: 5px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        cursor: pointer;
        span {
          margin-right: 10px;
        }
      }
    }
  }
  &--wrap {
    margin: 30px 0px;
    padding: 20px 10px;
  }
  &--user {
    display: flex;
    flex-direction: column;
    width: 50%;
    label {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #000000;
      margin: 0;
    }
    input {
      width: 100%;
      height: 100%;
      padding: 10px 10px 10px 15px;
      margin: 8px 0;
      display: inline-block;
      background: #fafafa;
      border: 0.5px solid #dfdfdf;
      box-sizing: border-box;
      border-radius: 5px;
      &:focus {
        outline: none;
      }
      &::placeholder {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 27px;
        color: #dfdfdf;
      }
    }
  }
  &--attachment {
    width: 100%;
    display: flex;
    flex-direction: column;
    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #000000;
      margin: 5px 0px;
    }
    .left {
      display: flex;
      // flex-direction: column;
      .svg-text {
        width: 200px;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
        background: #8c8c8c1f;
        border-radius: 5px;
        margin-right: 10px;
      }
      .imageOne {
        width: 200px;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
        border-radius: 5px;
        border: 0.5px solid #8c8c8c1f;
        background-color: #f5f5f5;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
        video {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  &--desc {
    margin-top: 20px;
    h1 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #000000;
      margin: 0;
    }
    label {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #000000;
      margin: 5px 0px;
    }
    #content {
      width: 100%;
      min-height: 92px;
      height: 100%;
      padding: 10px 10px 10px 15px;
      margin: 24px 0;
      display: inline-block;
      background: #fafafa;
      border: 0.5px solid #dfdfdf;
      box-sizing: border-box;
      border-radius: 5px;
      resize: none;
      &:focus {
        outline: none;
      }
    }
    ul {
      list-style-type: disc;
    }
    ol {
      list-style-type: decimal;
    }
    li {
      margin-left: 32px;
    }
  }
  &--switch {
    margin-top: 25px;
    &--heading {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 10px 25px;
      background: rgba(140, 140, 140, 0.3);
      border-radius: 15px 15px 0px 0px;
      .right {
        h1 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          color: #ffffff;
          margin-bottom: 0;
        }
      }
    }
    &--list {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 20px 25px;
      gap: 25px;
      border: 1px solid #dfdfdf;
      border-radius: 5px;
      flex-wrap: wrap;
      .list {
        &--wrap {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 25px 20px;
          gap: 10px;
          border: 0.5px solid #dfdfdf;
          border-radius: 10px;
          width: 210px;
          height: 150px;
          h1 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #666666;
            text-align: center;
          }
        }
      }
    }
  }
  &--btn {
    float: right;
    margin-top: 20px;
    // margin-right: 35px;
    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 24px;
      background: #4895ef;
      border-radius: 8px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.02em;
      color: #ffffff;
    }
  }
  #imageButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px 10px 10px 15px;
    background: #4895ef;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: #ffffff;
    height: 100%;
  }
}

.feed-view {
  padding: 20px 10px;
  margin: 0px 30px;
  &--heading {
    background: #ffffff;
    border-radius: 5px 5px 0px 0px;
    display: flex;
    justify-content: space-between;
    padding: 12px 10px;
    align-items: center;
    &--title {
      h1 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #000000;
        margin-bottom: 0;
      }
    }
    &-btn {
      button {
        background: #000000;
        border-radius: 5px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        cursor: pointer;
        span {
          margin-right: 10px;
        }
      }
    }
  }
  &--body {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 40px;
    margin: 25px 12px;
    width: 98%;
    &-wrapper {
      width: 30%;
      border: 1px solid #dfdfdf;
      background-color: #ffffff;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 10px 0px;
      gap: 1px;
      &--header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 10px 0px 15px;
        width: 100%;
        border-radius: 5px 5px 0px 0px;
        .profile-area {
          gap: 15px;
          padding: 0px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .avatar {
            width: 65px;
            height: 65px;
            border: 0.5px solid #dfdfdf;
            border-radius: 50%;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .detail {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: 0px;
            gap: 1px;

            h5 {
              font-family: "Poppins";
              font-weight: 500;
              // font-size: 16px;
              line-height: 27px;
              color: #000000;
            }
            h6 {
              font-family: "Poppins";
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              color: #666666;
              margin-top: -7px;
            }
            p {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: -5px;
              gap: 5px;
              color: #929292;
              font-family: "Poppins";
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
            }
          }
        }
        .profile {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding: 0px;
          h3 {
            font-family: "Poppins";
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #666666;
          }
          p {
            display: flex;
            gap: 10px;
            align-items: center;
            padding: 0px;
            font-family: "Poppins";
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #666666;
          }
        }
      }
      &--area {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 15px;
        gap: 5px;
        width: 100%;
        border-top: 1px solid #dfdfdf;
        .body {
          padding: 10px 0px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
          width: 100%;
          div {
            font-family: "Poppins";
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #666666;
            text-align: justify;
            width: 100%;
            overflow: auto;
            padding: 10px 10px 10px 0px;
            & > * {
              &::-webkit-scrollbar {
                display: none;
              }
              -ms-overflow-style: none;
              scrollbar-width: none;
            }
          }
        }
        .footer {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 15px;
          padding: 0px;
          height: 10px;
          align-self: stretch;
          p {
            font-family: "Poppins";
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
            letter-spacing: 0.05em;
            color: #000000;
            span {
              font-weight: 500;
              color: #666666;
            }
          }
        }
      }
    }

    &-wrapper1 {
      width: 100%;
      border: 1px solid #dfdfdf;
      background-color: #ffffff;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 10px 0px;
      gap: 1px;
      &--header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 10px 0px 15px;
        width: 100%;
        border-radius: 5px 5px 0px 0px;
        .profile-area {
          gap: 15px;
          padding: 0px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .avatar {
            width: 65px;
            height: 65px;
            border: 0.5px solid #dfdfdf;
            border-radius: 50%;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .detail {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: 0px;
            gap: 1px;

            h5 {
              font-family: "Poppins";
              font-weight: 500;
              // font-size: 16px;
              line-height: 27px;
              color: #000000;
            }
            h6 {
              font-family: "Poppins";
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              color: #666666;
              margin-top: -7px;
            }
            p {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: -5px;
              gap: 5px;
              color: #929292;
              font-family: "Poppins";
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
            }
          }
        }
        .profile {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding: 0px;
          h3 {
            font-family: "Poppins";
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #666666;
          }
          p {
            display: flex;
            gap: 10px;
            align-items: center;
            padding: 0px;
            font-family: "Poppins";
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #666666;
          }
        }
      }
      &--area {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 15px;
        gap: 5px;
        width: 100%;
        border-top: 1px solid #dfdfdf;
        .body {
          padding: 10px 0px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          gap: 10px;
          div {
            font-family: "Poppins";
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #666666;
            text-align: justify;
            overflow: auto;
            width: 100%;
            padding: 10px 10px 10px 0px;
            & > * {
              &::-webkit-scrollbar {
                display: none;
              }
              -ms-overflow-style: none;
              scrollbar-width: none;
            }
          }
        }
        .footer {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 15px;
          padding: 0px;
          height: 10px;
          align-self: stretch;
          p {
            font-family: "Poppins";
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
            letter-spacing: 0.05em;
            color: #000000;
            span {
              font-weight: 500;
              color: #666666;
            }
          }
        }
      }
    }

    &--article {
      width: 70%;
      height: 700px;
      border: 1px solid #dfdfdf;
      border-radius: 10px;
      background-color: #ffffff;
      padding: 10px;
      .media-area1 {
        display: grid;
        gap: 15px;
        padding: 10px;
        width: 100%;
        height: 100%;
        grid-template-columns: 66% 33%;
        grid-template-rows: 1fr 1fr;
        .media1 {
          grid-row-start: 1;
          grid-row-end: 3;
          border-radius: 8px;
          border: 0.1px solid #dfdfdf;
          &:focus {
            outline: none;
          }
          img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }
        }
        .media-video1 {
          grid-row-start: 1;
          grid-row-end: 3;
          border-radius: 8px;
          border: 0.1px solid #dfdfdf;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.1),
            rgba(0, 0, 0, 0.1)
          );
          &:focus {
            outline: none;
          }
          video {
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }
        }
        .media2 {
          border-radius: 8px;
          border: 0.1px solid #dfdfdf;
          &:focus {
            outline: none;
          }
          img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }
        }
        .media-video2 {
          border-radius: 8px;
          border: 0.1px solid #dfdfdf;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.1),
            rgba(0, 0, 0, 0.1)
          );
          &:focus {
            outline: none;
          }
          video {
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }
        }
        .media3 {
          position: relative;
          border-radius: 8px;
          border: 0.1px solid #dfdfdf;
          &:focus {
            outline: none;
          }
          img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }
          .mediaText {
            position: absolute;
            top: 0%;
            left: 0%;
            width: 100%;
            height: 100%;
            font-family: "Poppins";
            font-weight: 500;
            font-size: 70px;
            line-height: 105px;
            letter-spacing: 0.05em;
            color: #ffffff;
            text-align: center;
            background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.4),
              rgba(0, 0, 0, 0.4)
            );
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .media-video3 {
          position: relative;
          border-radius: 8px;
          border: 0.1px solid #dfdfdf;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.1),
            rgba(0, 0, 0, 0.1)
          );
          &:focus {
            outline: none;
          }
          video {
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }
          .media-text {
            position: absolute;
            top: 0%;
            left: 0%;
            width: 100%;
            height: 100%;
            font-family: "Poppins";
            font-weight: 500;
            font-size: 70px;
            line-height: 105px;
            letter-spacing: 0.05em;
            color: #ffffff;
            text-align: center;
            background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.4),
              rgba(0, 0, 0, 0.4)
            );
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .media-area2 {
        display: grid;
        gap: 15px;
        padding: 10px;
        width: 100%;
        height: 100%;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 100%;
        .media1 {
          border-radius: 8px;
          border: 0.1px solid #dfdfdf;
          &:focus {
            outline: none;
          }
          img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }
        }
        .media-video1 {
          border-radius: 8px;
          border: 0.1px solid #dfdfdf;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.1),
            rgba(0, 0, 0, 0.1)
          );
          &:focus {
            outline: none;
          }
          video {
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }
        }
        .media2 {
          border-radius: 8px;
          border: 0.1px solid #dfdfdf;
          &:focus {
            outline: none;
          }
          img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }
        }
        .media-video2 {
          border-radius: 8px;
          border: 0.1px solid #dfdfdf;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.1),
            rgba(0, 0, 0, 0.1)
          );
          &:focus {
            outline: none;
          }
          video {
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }
        }
      }
      .feed-modal-overlay {
        z-index: 9999;
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(223, 223, 223, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
      }
      .feed-modal-box {
        width: 55%;
        height: 78%;
        margin-left: 10%;
        background: #ffffff;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
        border-radius: 24px;
        padding: 0px;
        &--wrapper {
          width: 100%;
          height: 100%;
          padding: 15px;
          .heading {
            display: flex;
            justify-content: flex-end;
          }
          .content-wrapper {
            display: flex;
            align-items: flex-start;
            padding: 0px 15px;
            width: 100%;
            height: 95%;
            margin-bottom: 20px;
            .image-wrap {
              width: 60%;
              height: 90%;
              max-height: 90%;
              min-height: 90%;
              display: flex;
              flex-direction: column;
              gap: 20px;
              &:focus {
                outline: none;
              }
              .image-wrapper {
                height: 100%;
                min-height: 100%;
                max-height: 100%;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              .video-wrapper {
                border: 0.1px solid #dfdfdf;
                height: 100%;
                min-height: 100%;
                max-height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                background: linear-gradient(
                  0deg,
                  rgba(0, 0, 0, 0.1),
                  rgba(0, 0, 0, 0.1)
                );

                video {
                  width: 100%;
                  height: 100%;
                }
              }
              .action-button {
                height: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                button {
                  font-family: "Poppins";
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 18px;
                  letter-spacing: 0.05em;
                  color: #4895ef;
                  cursor: pointer;
                }
                button:disabled {
                  color: #666666;
                }
              }
            }
            .body-wrap {
              width: 40%;
              height: 90%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding: 15px;
              gap: 10px;
              &:focus {
                outline: none;
              }
              &--header {
                display: flex;
                align-items: center;
                padding: 0px 10px 0px 0px;
                width: 100%;
                border-radius: 5px 5px 0px 0px;
                border-bottom: 0.3px solid #dfdfdf;
                .profile-area {
                  gap: 15px;
                  padding: 0px;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  .avatar {
                    width: 65px;
                    height: 65px;
                    border: 0.5px solid #dfdfdf;
                    border-radius: 50%;
                    img {
                      width: 100%;
                      height: 100%;
                      border-radius: 50%;
                    }
                  }
                  .detail {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    padding: 0px;
                    gap: 1px;

                    h5 {
                      font-family: "Poppins";
                      font-weight: 500;
                      // font-size: 16px;
                      line-height: 27px;
                      color: #000000;
                    }
                    h6 {
                      font-family: "Poppins";
                      font-weight: 500;
                      font-size: 12px;
                      line-height: 18px;
                      color: #666666;
                      margin-top: -7px;
                    }
                    p {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin-top: -5px;
                      gap: 5px;
                      color: #929292;
                      font-family: "Poppins";
                      font-weight: 500;
                      font-size: 12px;
                      line-height: 18px;
                    }
                  }
                }
                .profile {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  padding: 0px;
                  h3 {
                    font-family: "Poppins";
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #666666;
                  }
                  p {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    padding: 0px;
                    font-family: "Poppins";
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: #666666;
                  }
                }
              }
              &--area {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 10px 0px;
                gap: 5px;
                width: 100%;
                .body {
                  padding: 10px 0px;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 10px;
                  width: 100%;
                  div {
                    font-family: "Poppins";
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 150%;
                    color: #666666;
                    text-align: justify;
                    overflow: auto;
                    padding: 10px 10px 10px 0px;
                    width: 100%;
                    & > * {
                      &::-webkit-scrollbar {
                        display: none;
                      }
                      -ms-overflow-style: none;
                      scrollbar-width: none;
                    }
                  }
                }
                .footer {
                  display: flex;
                  align-items: center;
                  width: 100%;
                  gap: 15px;
                  padding: 0px;
                  height: 10px;
                  align-self: stretch;
                  p {
                    font-family: "Poppins";
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 21px;
                    letter-spacing: 0.05em;
                    color: #666666;
                  }
                }
              }
            }
          }
        }
      }
    }
    &--article1 {
      width: 70%;
      height: 780px;
      border: 1px solid #dfdfdf;
      border-radius: 10px;
      background-color: #ffffff;
      padding: 10px;
      .media-area3 {
        display: grid;
        gap: 15px;
        padding: 10px;
        width: 100%;
        height: 100%;
        grid-template-columns: 100%;
        grid-template-rows: 100%;
        .media1 {
          border-radius: 8px;
          border: 0.1px solid #dfdfdf;
          &:focus {
            outline: none;
          }
          img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }
        }
        .media-video1 {
          border-radius: 8px;
          border: 0.1px solid #dfdfdf;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.1),
            rgba(0, 0, 0, 0.1)
          );
          &:focus {
            outline: none;
          }
          video {
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }
        }
      }
      .feed-modal-overlay {
        z-index: 9999;
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(223, 223, 223, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
      }
      .feed-modal-box {
        width: 55%;
        height: 78%;
        margin-left: 10%;
        background: #ffffff;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
        border-radius: 24px;
        padding: 0px;
        &--wrapper {
          width: 100%;
          height: 100%;
          padding: 15px;
          .heading {
            display: flex;
            justify-content: flex-end;
          }
          .content-wrapper {
            display: flex;
            align-items: flex-start;
            padding: 0px 15px;
            width: 100%;
            height: 95%;
            margin-bottom: 20px;
            .image-wrap {
              width: 60%;
              height: 90%;
              max-height: 90%;
              min-height: 90%;
              display: flex;
              flex-direction: column;
              gap: 20px;
              &:focus {
                outline: none;
              }
              .image-wrapper {
                height: 100%;
                min-height: 100%;
                max-height: 100%;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              .video-wrapper {
                border: 0.1px solid #dfdfdf;
                height: 100%;
                min-height: 100%;
                max-height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                background: linear-gradient(
                  0deg,
                  rgba(0, 0, 0, 0.1),
                  rgba(0, 0, 0, 0.1)
                );

                video {
                  width: 100%;
                  height: 100%;
                }
              }
              .action-button {
                height: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                button {
                  font-family: "Poppins";
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 18px;
                  letter-spacing: 0.05em;
                  color: #4895ef;
                  cursor: pointer;
                }
                button:disabled {
                  color: #666666;
                }
              }
            }
            .body-wrap {
              width: 40%;
              height: 90%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding: 15px;
              gap: 10px;
              &:focus {
                outline: none;
              }
              &--header {
                display: flex;
                align-items: center;
                padding: 0px 10px 0px 0px;
                width: 100%;
                border-radius: 5px 5px 0px 0px;
                border-bottom: 0.3px solid #dfdfdf;
                .profile-area {
                  gap: 15px;
                  padding: 0px;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  .avatar {
                    width: 65px;
                    height: 65px;
                    border: 0.5px solid #dfdfdf;
                    border-radius: 50%;
                    img {
                      width: 100%;
                      height: 100%;
                      border-radius: 50%;
                    }
                  }
                  .detail {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    padding: 0px;
                    gap: 1px;

                    h5 {
                      font-family: "Poppins";
                      font-weight: 500;
                      // font-size: 16px;
                      line-height: 27px;
                      color: #000000;
                    }
                    h6 {
                      font-family: "Poppins";
                      font-weight: 500;
                      font-size: 12px;
                      line-height: 18px;
                      color: #666666;
                      margin-top: -7px;
                    }
                    p {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin-top: -5px;
                      gap: 5px;
                      color: #929292;
                      font-family: "Poppins";
                      font-weight: 500;
                      font-size: 12px;
                      line-height: 18px;
                    }
                  }
                }
                .profile {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  padding: 0px;
                  h3 {
                    font-family: "Poppins";
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #666666;
                  }
                  p {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    padding: 0px;
                    font-family: "Poppins";
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: #666666;
                  }
                }
              }
              &--area {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 10px 0px;
                gap: 5px;
                width: 100%;
                .body {
                  padding: 10px 0px;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 10px;
                  width: 100%;
                  div {
                    font-family: "Poppins";
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 150%;
                    color: #666666;
                    text-align: justify;
                    width: 100%;
                    padding: 10px 10px 10px 0px;
                    overflow: auto;
                    & > * {
                      &::-webkit-scrollbar {
                        display: none;
                      }
                      -ms-overflow-style: none;
                      scrollbar-width: none;
                    }
                  }
                }
                .footer {
                  display: flex;
                  align-items: center;
                  width: 100%;
                  gap: 15px;
                  padding: 0px;
                  height: 10px;
                  align-self: stretch;
                  p {
                    font-family: "Poppins";
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 21px;
                    letter-spacing: 0.05em;
                    color: #666666;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .project-body {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 60px;
    margin: 40px 0px;
    width: 100%;
    &--detail {
      width: 70%;
      &-header {
        display: flex;
        flex-direction: column;
        gap: 28px;
        .title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          &-area {
            display: flex;
            align-items: center;
            gap: 10px;
            h2 {
              font-family: "Poppins";
              font-size: 24px;
              font-style: normal;
              font-weight: 500;
              line-height: 30px;
              color: #181818;
            }
            &-post {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 12px;
              h5 {
                font-family: "Poppins";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                color: #181818;
              }
              p {
                display: flex;
                justify-content: center;
                align-items: flex-end;
                gap: 4px;
                font-family: "Poppins";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                color: #565656;
                span {
                  margin-top: 4px;
                }
              }
            }
          }
          &-buttons {
            display: flex;
            align-items: flex-start;
            .reject {
              display: flex;
              align-items: center;
              justify-content: center;
              align-self: stretch;
              gap: 4px;
              padding: 12px 14px;
              font-family: "Poppins";
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
              letter-spacing: 0.28px;
              color: #db2128;
              text-align: center;
              border-radius: 4px;
              &-button {
                display: flex;
                align-items: center;
                justify-content: center;
                align-self: stretch;
                gap: 4px;
                padding: 12px 14px;
                font-family: "Poppins";
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: 0.28px;
                color: #db2128;
                text-align: center;
                border-radius: 4px;
                background: #ffffff;
                svg {
                  margin-right: 3px;
                }
              }
            }
            .accept {
              display: flex;
              padding: 12px 14px;
              justify-content: center;
              align-items: center;
              align-self: stretch;
              border-radius: 4px;
              background: #4895ef;
              color: #ffffff;
              text-align: center;
              font-family: "Poppins";
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
              letter-spacing: 0.28px;
              &-button {
                display: flex;
                padding: 12px 14px;
                justify-content: center;
                align-items: center;
                align-self: stretch;
                border-radius: 4px;
                background: #ffffff;
                color: #32c769;
                text-align: center;
                font-family: "Poppins";
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: 0.28px;
                svg {
                  margin-right: 7px;
                }
              }
            }
          }
        }
        .subtitle {
          display: flex;
          gap: 16px;
          align-items: center;
          padding: 10px 0px;
          border-top: 1px solid #e7e7e7;
          border-bottom: 1px solid #e7e7e7;
          &-location {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-right: 1px solid #e7e7e7;
            padding: 0px 12px;
            h5 {
              display: flex;
              align-items: center;
              gap: 4px;
              color: #ed2121;
              font-family: "Poppins";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
            }
            p {
              color: #181818;
              font-family: "Poppins";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
            }
          }
          &-duration {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-right: 1px solid #e7e7e7;
            padding: 0px 12px;
            h5 {
              display: flex;
              align-items: center;
              gap: 4px;
              color: #4264df;
              font-family: "Poppins";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
            }
            p {
              color: #181818;
              font-family: "Poppins";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
            }
          }
          &-experience {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-right: 1px solid #e7e7e7;
            padding: 0px 12px;
            h5 {
              display: flex;
              align-items: center;
              gap: 4px;
              color: #efa148;
              font-family: "Poppins";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
            }
            p {
              color: #181818;
              font-family: "Poppins";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
            }
          }
          &-budject {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-right: 1px solid #e7e7e7;
            padding: 0px 12px;
            h5 {
              display: flex;
              align-items: center;
              gap: 4px;
              color: #307c5a;
              font-family: "Poppins";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
            }
            p {
              color: #181818;
              font-family: "Poppins";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
            }
          }
        }
        .cover {
          width: 100%;
          height: 200px;
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
        .description {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
          h5 {
            color: #181818;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
          }
          p {
            color: var(--light-greyscale-text-secondary, #565656);
            text-align: justify;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            align-self: stretch;
            word-break: break-word;
          }
        }
        .skills {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
          h5 {
            color: #181818;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
          }
          &-area {
            display: flex;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            &--item {
              display: flex;
              padding: 8px 16px;
              justify-content: center;
              align-items: center;
              border-radius: 8px;
              background: #edf1ff;
              color: #4264df;
              text-align: center;
              font-family: "Poppins";
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px;
            }
          }
        }
        .keywords {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
          h5 {
            color: #181818;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
          }
          &-area {
            display: flex;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            &--item {
              display: flex;
              padding: 8px 16px;
              justify-content: center;
              align-items: center;
              border-radius: 8px;
              background: #f8f1e0;
              color: #ca8410;
              text-align: center;
              font-family: "Poppins";
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px;
            }
          }
        }
        .attachments {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
          h5 {
            color: #181818;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
          }
          &-area {
            display: flex;
            align-items: flex-start;
            gap: 16px;
            align-self: stretch;
            &--item {
              display: flex;
              padding: 8px 16px;
              justify-content: center;
              align-items: center;
              gap: 16px;
              border: 0.3px solid #e9f0fa;
              border-radius: 8px;
              background: #ffffff;
              .details {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: 10px;
                h6 {
                  color: #181818;
                  font-family: "Poppins";
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 14px;
                }
                p {
                  color: #565656;
                  font-family: "Poppins";
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 12px;
                }
              }
            }
          }
        }
        .faq {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
          h5 {
            color: #181818;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
          }
          &-area {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            align-self: stretch;
            border-radius: 5px;
            &--item {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              align-self: stretch;
              border-radius: 5px;
              .question {
                display: flex;
                padding: 12px 0px;
                align-items: center;
                gap: 0px;
                align-self: stretch;
                h6 {
                  display: flex;
                  width: 32px;
                  height: auto;
                  justify-content: center;
                  align-items: center;
                  border-radius: 5px;
                  background: #dfdfdf;
                  color: #666666;
                  font-family: "Poppins";
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 500;
                  margin: 0px;
                }
                p {
                  color: #565656;
                  font-family: Poppins;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px;
                  padding: 3px 0px 0px 12px;
                  display: flex;
                  align-items: flex-start;
                  justify-content: center;
                  margin: 0px;
                }
              }
              .answer {
                display: flex;
                padding: 12px 0px;
                align-items: center;
                gap: 0px;
                align-self: stretch;
                h6 {
                  display: flex;
                  width: 32px;
                  height: auto;
                  justify-content: center;
                  align-items: center;
                  border-radius: 5px;
                  background: #4895ef;
                  color: #ffffff;
                  font-family: "Poppins";
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 500;
                  margin: 0px;
                }
                p {
                  color: #181818;
                  font-family: Poppins;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px;
                  padding: 3px 0px 0px 12px;
                  display: flex;
                  align-items: flex-start;
                  justify-content: center;
                  margin: 0px;
                }
              }
            }
          }
        }
      }
    }
    &--buyer {
      width: 30%;
      display: flex;
      padding: 24px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      border-radius: 8px;
      border: 0.4px solid #d6d6d6;
      background: #ffffff;
      h4 {
        color: #141414;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }
      .buyer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-bottom: 12px;
        border-bottom: 0.4px solid #e7e7e7;
        &-area {
          display: flex;
          align-items: center;
          gap: 8px;
          &-image {
            width: 45px;
            height: 45px;
            border-radius: 50px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          &-detail {
            display: flex;
            flex-direction: column;
            h6 {
              color: #565656;
              font-family: "Poppins";
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              margin-bottom: 0px;
            }
            p {
              color: #4264df;
              font-family: "Poppins";
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              margin-bottom: 0px;
            }
          }
        }
        &-rating {
          display: flex;
          padding: 10px 4px;
          align-items: center;
          gap: 8px;
          border-radius: 8px;
          background: #fafafa;
          color: #efa148;
          text-align: center;
          font-family: "Poppins";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.28px;
        }
      }
      .conditions {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        width: 100%;
        padding-bottom: 12px;
        border-bottom: 0.4px solid #e7e7e7;
        &-location {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
        }
        &-posted {
          color: #181818;
          font-family: "Poppins";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
        }
        &-spent {
          color: #181818;
          font-family: "Poppins";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
        }
        &-duration {
          color: #565656;
          font-family: "Poppins";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
        }
      }
      .about {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        h6 {
          color: #181818;
          font-family: "Poppins";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
        }
        p {
          color: #565656;
          font-family: "Poppins";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          text-align: justify;
        }
      }
      button {
        display: flex;
        padding: 12px 14px;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        border-radius: 4px;
        color: #4895ef;
        text-align: center;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.28px;
        &:hover {
          background: #edf1ff;
        }
      }
    }
  }
}
.modal-overlay {
  z-index: 9999;
  width: 80vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 20%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-box {
  display: block;
  background: #ffffff;
  width: 60%;
  margin-left: -15%;
  max-height: 80%;
  overflow-y: auto;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  &--wrapper {
    padding: 5px 20px;
    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h1 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: #000000;
      }
    }
    .form-wrap {
      margin-top: 20px;
      &--module {
        display: flex;
        flex-direction: column;
        &_cell {
          margin: 10px;
          width: 50%;
        }
        label {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.24px;
          color: #000000;
        }
        input[type="text"],
        input[type="number"],
        #role {
          width: 100%;
          padding: 12px 20px;
          margin: 8px 0;
          display: inline-block;
          background: #fafafa;
          border: 0.3px solid #dfdfdf;
          box-sizing: border-box;
          border-radius: 5px;
          -moz-appearance: textfield;
          appearance: textfield;
          &:focus {
            outline: none;
          }
          &::placeholder {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 27px;
            color: #dfdfdf;
          }
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        textarea {
          width: 100%;
          height: 250px;
          padding: 10px 10px 10px 15px;
          margin: 8px 0;
          display: inline-block;
          background: #fafafa;
          border: 0.5px solid #dfdfdf;
          box-sizing: border-box;
          border-radius: 5px;
          resize: none;
          &:focus {
            outline: none;
          }
          &::placeholder {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 27px;
            color: #dfdfdf;
          }
        }
      }
      &--submodule {
        display: flex;
        flex-direction: row;
        &_cell {
          margin: 10px;
          width: 50%;
          &_i {
            width: 100%;
            margin: 10px;
          }
          #imageButton {
            padding: 13px 10px;
            background: #4895ef;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            font-family: "Poppins";
            font-weight: 500;
            font-size: 14px;
            text-align: center;
            color: #ffffff;
            height: 46px;
          }
        }
        label {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.24px;
          color: #666666;
        }
        input[type="text"],
        input[type="number"],
        input[type="file"],
        #module {
          width: 100%;
          max-height: 50px;
          padding: 12px 20px;
          margin: 8px 0;
          display: inline-block;
          background: #fafafa;
          border: 0.3px solid #dfdfdf;
          box-sizing: border-box;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          -moz-appearance: textfield;
          appearance: textfield;
          &:focus {
            outline: none;
          }
          &::placeholder {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 27px;
            color: #dfdfdf;
          }
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
      &--desc {
        margin-top: 20px;
        label {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.24px;
          color: #666666;
        }
        textarea {
          width: 100%;
          height: 50%;
          padding: 10px 10px 10px 15px;
          margin: 8px 0;
          display: inline-block;
          background: #fafafa;
          border: 0.5px solid #dfdfdf;
          box-sizing: border-box;
          border-radius: 5px;
          resize: none;
          &:focus {
            outline: none;
          }
          &::placeholder {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 27px;
            color: #dfdfdf;
          }
        }
      }
      &--bottom {
        .btns {
          margin-top: 15px;
          &--wrap {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 24px;
            .reset {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              display: flex;
              align-items: center;
              text-align: center;
              letter-spacing: 0.02em;
              color: #4895ef;
              padding: 12px 24px;
              background: #e5f2fd;
              border-radius: 8px;
            }
            .save {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              display: flex;
              align-items: center;
              text-align: center;
              letter-spacing: 0.02em;
              color: #ffffff;
              padding: 12px 24px;
              background: #4895ef;
              border-radius: 8px;
            }
          }
        }
      }
    }
  }
}
.milestone {
  h5{
    font-family: "Poppins";
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    color: #181818;
    text-align: justify;
  }
  &-area{
    width: 100%;
      display: flex;
    align-items: flex-start;
    gap: 24px;
    flex-wrap: wrap;
    &--item{
      width: 35%;
      &--content{
        border-radius: 8px;
        border: 0.4px solid var(--Light-Greyscale-stroke, #D6D6D6);
        background: var(--Light-Background-bg-light, #FFF);
        padding: 8px;
        h1{
          color: var(--Light-Greyscale-Text-primary, #181818);
          text-align: justify;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px; 
        }
        &--box{
          h6{
          color: var(--Dark-Accent-accent1-primary, #4264DF);
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; 
          }
          &--desc{
            display: flex;
            align-items:center;
            gap:4px;
            color: var(--Light-Accent-Green-click-state, #307C5A);
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 125% */
          }
          h2{
            color: var(--Light-Greyscale-Text-primary, #181818);
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; 
          }
          &--descs{
          color: var(--Light-Greyscale-Text-secondary, #565656);
          text-align: justify;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; 
          word-wrap: normal;
          }
        }
      }
    }
  }
}
