// .modules {
//   margin: 0 2%;
//   &__header {
//     margin-top: 3%;
//     margin-bottom: 3%;
//     &-row {
//       display: flex;
//       justify-content: space-between;
//       background: #ffffff;
//       padding: 10px 20px;
//       align-items: center;
//     }
//   }
// }

@import "antd/dist/antd.css";

.ant-skeleton
// .ant-skeleton-active,
// .ant-skeleton-round,
// .ant-skeleton-content,
// .ant-skeleton-title {
{
  width: 50%;
}
