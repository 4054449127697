.ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 24px;
  text-align: right;
  display: flex;
  justify-content: space-between;
}
.ant-modal-confirm-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ant-modal-confirm-body
  > .anticon
  + .ant-modal-confirm-title
  + .ant-modal-confirm-content {
  margin-left: 0px !important;
}
.ant-modal-confirm-body > .anticon {
  float: left;
  margin-right: 0px !important;
  font-size: 22px;
}
.__btn {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  border-color: transparent !important;
  color: #8c8c8c !important;
  box-shadow: none !important;
  background-color: transparent !important;
  padding: 0 !important;
}
span.ant-dropdown-menu-title-content {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  border-color: transparent !important;
  color: #8c8c8c !important;
  box-shadow: none !important;
  background-color: transparent !important;
  padding: 0 !important;
}
span.ant-modal-confirm-title {
  margin-top: 15px !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #ff7a00 !important;
}
.ant-modal-confirm-content {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #666666 !important;
}
.duplicate-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  svg {
    margin-bottom: 15px;
  }
  h1 {
    color: #ff7a00;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
  }
  p {
    color: #666666;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    margin-bottom: 40px;
  }
  &--footer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    &_cancel {
      border: 0.3px solid #666666;
      border-radius: 5px;
      width: 101px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      color: #666666;
      font-size: 16px;
      font-weight: 500;
      font-family: "Poppins";
      line-height: 24px;
    }
    &_ok {
      border: 0.3px solid #ff7a00;
      background-color: #ff7a00;
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      font-family: "Poppins";
      line-height: 24px;
      border-radius: 5px;
      width: 101px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
    }
  }
}
.status {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}
.user-active-status {
  background-color: #32c76914;
  color: #32c769;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 180px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
  flex-direction: row;
  span {
    margin-right: 10px;
  }
}

.user-pending-status {
  background-color: #4895ef14;
  color: #4895ef;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 180px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
  flex-direction: row;
  span {
    margin-right: 10px;
  }
}
.user-inactive-status {
  background-color: #fafafa;
  color: #666666;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 180px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
  flex-direction: row;
  span {
    margin-right: 10px;
  }
}
.user-cancelled-status {
  background-color: rgba(244, 127, 32, 0.08);
  color: rgba(244, 127, 32, 1);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 180px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
  flex-direction: row;
  span {
    margin-right: 10px;
  }
}
.group-active-status {
  color: #32c769;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 80px;
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
}
.group-inactive-status {
  color: #8c8c8c;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 80px;
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
}
th.ant-table-cell {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #000000 !important;
}
.system-admin {
  position: relative;
  &--wrapper {
    position: absolute;
    //  width: 100px;
    //  height: 100px;
    top: 50%;
    left: 55%;
    transform: translate(-50%, 15%);
  }
  &--img {
    width: 100%;
    max-width: 500px;
    img {
      width: 100%;
      height: auto;
    }
  }
  &--content {
    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 33px;
      // text-align: center;
      color: #666666;
    }
  }
  &-btn {
    display: flex;
    justify-content: center;
    margin-top: 23px;
    button {
      background: #4895ef;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 24px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
    }
  }
}
.modal-overlay {
  z-index: 9999;
  width: 70vw;
  height: 100vh;
  position: absolute;
  top: 0;
  // background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-box {
  display: block;
  background: white;
  width: 50%;
  //height: 50%;
  padding: 1rem;
  border-radius: 1rem;
  &--wrapper {
    padding: 20px 20px;
    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h1 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: #000000;
      }
    }
    .form-wrap {
      margin-top: 20px;
      &--module {
        label {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.24px;
          color: #666666;
        }
        input[type="text"] {
          width: 100%;
          padding: 12px 20px;
          margin: 8px 0;
          display: inline-block;
          background: #fafafa;
          border: 0.3px solid #dfdfdf;
          box-sizing: border-box;
          border-radius: 5px;
          &:focus {
            outline: none;
          }
          &::placeholder {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 27px;
            color: #dfdfdf;
          }
        }
      }
      &--desc {
        margin-top: 20px;
        label {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.24px;
          color: #666666;
        }
        textarea {
          width: 100%;
          height: 50%;
          padding: 10px 10px 10px 15px;
          margin: 8px 0;
          display: inline-block;
          background: #fafafa;
          border: 0.5px solid #dfdfdf;
          box-sizing: border-box;
          border-radius: 5px;
          resize: none;
          &:focus {
            outline: none;
          }
          &::placeholder {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 27px;
            color: #dfdfdf;
          }
        }
      }
      &--bottom {
        .btns {
          margin-top: 15px;
          &--wrap {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 24px;
            .reset {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              display: flex;
              align-items: center;
              text-align: center;
              letter-spacing: 0.02em;
              color: #4895ef;
              padding: 12px 24px;
              background: #e5f2fd;
              border-radius: 8px;
            }
            .save {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              display: flex;
              align-items: center;
              text-align: center;
              letter-spacing: 0.02em;
              color: #ffffff;
              padding: 12px 24px;
              background: #4895ef;
              border-radius: 8px;
            }
          }
        }
      }
    }
  }
}
.roles {
  &-wrapper {
    padding: 10px;
  }
  &--heading {
    padding: 5px 25px;
    background: #ffffff;
    border-radius: 15px 15px 0px 0px;
    &-img {
      display: flex;
      justify-content: flex-end;
      img {
        width: 100%;
        max-width: 70px;
        height: auto;
        max-height: 70px;
        height: 70px;
        border-radius: 100%;
      }
    }
  }
  &--tables {
    margin-top: 25px;
    &-heading {
      &-title {
        padding: 20px 0;
        h2 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          color: #000000;
        }
      }
      &-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .formy {
          position: relative;
          &--box {
            width: 400px;
            height: 60px;
            input {
              padding: 13px 25px;
              background: #ffffff;
              border: 0.3px solid #dfdfdf;
              border-radius: 4px;
              width: 100%;
              color: #c4c4c4;
              &:focus {
                outline: none;
              }
              &::placeholder {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 300;
                font-size: 18px;
                line-height: 27px;
                color: #dfdfdf;
              }
            }
            &-icon {
              position: absolute;
              top: 13px;
              left: 10px;
              i.fa.fa-search {
                color: #dfdfdf;
              }
            }
          }
        }
        .clicks {
          display: flex;
          gap: 14px;
          &--right {
            button {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              display: flex;
              align-items: center;
              text-align: center;
              color: #ffffff;
              background: #4895ef;
              border-radius: 8px;
              padding: 12px 24px;
              gap: 14px;
            }
          }
          &--left {
            button {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              display: flex;
              align-items: center;
              text-align: center;
              color: #8c8c8c;
              background: #fafafa;
              border-radius: 8px;
              padding: 12px 24px;
              gap: 14px;
            }
          }
          .modal-overlayy {
            z-index: 9999;
            width: 80vw;
            height: 100vh;
            position: absolute;
            top: 0;
            left: 20%;
            background: rgba(0, 0, 0, 0.7);
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .modal-box {
            display: block;
            background: white;
            width: 60%;
            //height: 50%;
            padding: 1rem;
            border-radius: 1rem;
            &--wrapper {
              padding: 5px 20px;
              .heading {
                display: flex;
                justify-content: space-between;
                align-items: center;
                h1 {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 18px;
                  line-height: 27px;
                  color: #000000;
                }
              }
              .form-wrap {
                margin-top: 20px;
                &--module {
                  display: flex;
                  flex-direction: row;
                  &_cell {
                    margin: 10px;
                    width: 50%;
                  }
                  label {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: -0.24px;
                    color: #666666;
                  }
                  input[type="text"],
                  input[type="number"],
                  #role {
                    width: 100%;
                    padding: 12px 20px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.3px solid #dfdfdf;
                    box-sizing: border-box;
                    border-radius: 5px;
                    -moz-appearance: textfield;
                    appearance: textfield;
                    &:focus {
                      outline: none;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 300;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                  input::-webkit-outer-spin-button,
                  input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                  }
                }
                &--desc {
                  margin-top: 20px;
                  label {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: -0.24px;
                    color: #666666;
                  }
                  textarea {
                    width: 100%;
                    height: 50%;
                    padding: 10px 10px 10px 15px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.5px solid #dfdfdf;
                    box-sizing: border-box;
                    border-radius: 5px;
                    resize: none;
                    &:focus {
                      outline: none;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 300;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                }
                &--bottom {
                  .btns {
                    margin-top: 15px;
                    &--wrap {
                      display: flex;
                      justify-content: flex-end;
                      align-items: center;
                      gap: 24px;
                      .reset {
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        letter-spacing: 0.02em;
                        color: #4895ef;
                        padding: 12px 24px;
                        background: #e5f2fd;
                        border-radius: 8px;
                      }
                      .save {
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        letter-spacing: 0.02em;
                        color: #ffffff;
                        padding: 12px 24px;
                        background: #4895ef;
                        border-radius: 8px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &--left {
          button {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #8c8c8c;
            background: #fafafa;
            border-radius: 8px;
            padding: 12px 24px;
            gap: 14px;
          }
        }
      }
    }
  }
}
.admin-edit {
  padding: 20px 10px;
  &--heading {
    background: #ffffff;
    border-radius: 5px 5px 0px 0px;
    display: flex;
    justify-content: space-between;
    padding: 12px 10px;
    align-items: center;
    &--title {
      h1 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #000000;
        margin-bottom: 0;
      }
    }
    &-btn {
      button {
        background: #000000;
        border-radius: 5px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        cursor: pointer;
        span {
          margin-right: 10px;
        }
      }
    }
  }
  &--wrap {
    margin: 30px 0px;
    padding: 20px 10px;
    // background-color: #fff;
    h1 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #000000;
    }
  }
  &--group {
    width: 97%;
    margin: auto;
    margin-top: 30px;
    padding: 20px;
    background-color: #fff;
    border: 0.5px solid #dfdfdf;
    border-radius: 10px;
    h1 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #000000;
    }
  }
  &--group-form {
    margin-top: 25px;
    gap: 30px;
    display: flex;
    width: 100%;
    .right {
      display: flex;
      flex-direction: column;
      width: 100%;
      label {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #000000;
        margin: 0;
      }
      input[type="text"],
      input[type="number"],
      input[type="file"],
      select {
        width: 100%;
        height: 100%;
        padding: 10px 10px 10px 15px;
        margin: 8px 0;
        display: inline-block;
        background: #fafafa;
        border: 0.5px solid #dfdfdf;
        box-sizing: border-box;
        border-radius: 5px;
        -moz-appearance: textfield;
        appearance: textfield;
        &:focus {
          outline: none;
        }
        &::placeholder {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          line-height: 27px;
          color: #dfdfdf;
        }
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    .left {
      display: flex;
      flex-direction: column;
      width: 100%;
      label {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #000000;
        margin: 0;
      }
      input[type="text"],
      input[type="number"],
      input[type="file"],
      select {
        width: 100%;
        height: 100%;
        padding: 10px 10px 10px 15px;
        margin: 8px 0;
        display: inline-block;
        background: #fafafa;
        border: 0.5px solid #dfdfdf;
        box-sizing: border-box;
        border-radius: 5px;
        -moz-appearance: textfield;
        appearance: textfield;
        &:focus {
          outline: none;
        }
        &::placeholder {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          line-height: 27px;
          color: #dfdfdf;
        }
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      #imageInput {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
  }
  &--flex {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    gap: 30px;
    .right {
      padding: 10px 10px 10px 10px;
      border: 1px solid #dfdfdf;
      border-radius: 5px;
      width: 100%;
      max-width: 450px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      p {
        display: flex;
        align-items: center;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #8c8c8c;
        margin: 0;
      }
    }
    .left {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      align-items: center;
      padding: 10px 15px;
      gap: 10px;
      background: #ffffff;
      border: 0.3px solid #dfdfdf;
      border-radius: 5px;
      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin: 0;
      }
    }
  }
  &--user {
    margin-top: 15px;
    gap: 30px;
    display: flex;
    width: 100%;
    .right {
      display: flex;
      flex-direction: column;
      width: 100%;
      label {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #000000;
        margin: 0;
      }
      input,
      #role_id {
        width: 100%;
        height: 100%;
        padding: 10px 10px 10px 15px;
        margin: 8px 0;
        display: inline-block;
        background: #fafafa;
        border: 0.5px solid #dfdfdf;
        box-sizing: border-box;
        border-radius: 5px;
        &:focus {
          outline: none;
        }
        &::placeholder {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          line-height: 27px;
          color: #dfdfdf;
        }
      }
    }
    .left {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      align-items: center;
      padding: 10px 15px;
      gap: 10px;
      background: #ffffff;
      border: 0.3px solid #dfdfdf;
      border-radius: 5px;
      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin: 0;
      }
    }
  }
  &--desc {
    margin-top: 20px;
    h1 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #000000;
      margin: 0;
    }
    textarea {
      width: 100%;
      min-height: 92px;
      height: 100%;
      padding: 10px 10px 10px 15px;
      margin: 8px 0;
      display: inline-block;
      background: #fafafa;
      border: 0.5px solid #dfdfdf;
      box-sizing: border-box;
      border-radius: 5px;
      resize: none;
      &:focus {
        outline: none;
      }
      &::placeholder {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 27px;
        color: #dfdfdf;
      }
    }
  }
  &--switch {
    margin-top: 25px;
    &--heading {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 10px 25px;
      background: rgba(140, 140, 140, 0.3);
      border-radius: 15px 15px 0px 0px;
      .right {
        h1 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          color: #ffffff;
          margin-bottom: 0;
        }
      }
    }
    &--list {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 20px 25px;
      gap: 25px;
      border: 1px solid #dfdfdf;
      border-radius: 5px;
      flex-wrap: wrap;
      .list {
        &--wrap {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 25px 20px;
          gap: 10px;
          border: 0.5px solid #dfdfdf;
          border-radius: 10px;
          width: 210px;
          height: 150px;
          h1 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #666666;
            text-align: center;
          }
        }
      }
    }
  }
  &--btn {
    margin-top: 15px;
    float: right;
    margin-right: 35px;
    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 24px;
      background: #4895ef;
      border-radius: 8px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.02em;
      color: #ffffff;
    }
  }
  #imageButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px 10px 10px 15px;
    background: #4895ef;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: #ffffff;
    height: 100%;
  }
}
