.chat {
  width: 100%;
  float: right;

  &_header {
    display: flex;
    width: 100%;
    max-width: 375px;
    height: 52px;
    padding: 0px 16px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border-radius: 10px 10px 0px 0px;
    background: #101010;

    &_right {
      .img {
        position: relative;
        display: flex;
        align-items: center;
        gap: 10px;

        &_image {
          width: 40px;
          height: 40px;
          border-radius: 100%;
        }

        h6 {
          color: #fff;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    &_left {
      button {
        background-color: transparent;
      }
    }
  }

  &_body {
    border-radius: 10px 10px 0px 0px;
    background: #fff;
    width: 100%;
    height: 350px;
    overflow-y: auto;

    &_container {
      .sender {
        padding: 10px 10px;

        &_wrap {
          margin: 10px 0;
        }
        h1{
        color: #000;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;  
        }

        &_sender {
          color: #000;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
        }

        &_message {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;

          &_item {
            width: 360px;

            &_admin {
              margin-left: auto;
            }
          }

          p {
            color: #8c8c8c;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            border-radius: 5px;
            background: #f5f5f5;
            padding: 10px 5px 10px 10px;
          }
        }
      }

      .receiver {
        float: right;
        padding: 10px 10px;

        &_wrap {
          display: flex;
          justify-content: flex-start;
          margin: 10px 0;
        }

        &_sender {
          color: #000;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
        }

        &__recipient {
          width: 335px;
          height: 44px;

          p {
            color: #8c8c8c;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            border-radius: 5px;
            background: #f5f5f5;
            padding: 10px 5px 10px 10px;
          }
        }
      }
    }
  }

  &_inputContainer {
    width: 100%;
    margin-top: 3px;

    .message {
      display: flex;
      width: 100%;
      max-width: 375px;
      height: 50px;
      padding: 3px;
      color: #8c8c8c;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      padding: 10px 5px 10px 10px;
      border-radius: 5px;
      background: #fff;
      border: 1px solid #fafafa;
      position: relative;
    }

    .uploadIconContainer {
      position: relative;
    }

    .fileUpload {
      display: none;
    }

    .sendBtn {
      color: #fff;
      text-align: center;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border-radius: 8px;
      background: var(--Color-Light-Blue-Primary, #4895ef);
      padding: 10px;
    }

    .buttonIcon {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      padding: 5px 0;
    }
  }

  &_lists {
    &_box {
      border-radius: 10px 10px 0px 0px;
      background: #fff;
      width: 100%;
      max-width: 375px;
      height: 350px;
      overflow-y: auto;
    }

    &_items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      padding: 10px 8px;

      .img {
        &_profile {
          width: 40px;
          height: 40px;
          border-radius: 100%;
        }
      }

      .userdetail {
        h3 {
          color: #000;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
        }

        p {
          color: #8c8c8c;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
        }
      }

      .noti {
        display: flex;
        width: 24px;
        height: 24px;
        padding: 8px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        color: #fff;
        text-align: center;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border-radius: 50px;
        background: var(--Color-Light-Blue-Primary, #4895ef);
      }
    }
  }
}
