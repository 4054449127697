
.welcome-dashboard {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  background-color: #EDEDED;

  &_wrapper {
    display: flex;
    flex-direction: row;
    font-weight: bold;
    align-items: center;
    &-user1 {
      font-family: "Poppins";
      font-weight: 700;
      font-size: 35px;
      line-height: 72px;
      // width: 370px;
      height: 39px;
      margin-left: 30px;
      color: #666666;

      i {
        color: #000;
        margin-left: -17px;
      }
    }
  }
  &_wrapper2 {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 600;
    width: 160px;
    height: 19px;
    color: #4895ef;
    margin: 14px 165px;
    font-size: 20px;
  }
  &_admin {
    width: 679px;
    height: 100px;
    position: absolute;
    left: 110px;
    top: 280px;
    font-style: italic;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 27px;
    color: #666666;
    line-height: 72px;
    margin: 0px 10px;
  }
  &_footer-wrap {
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 0px 10px;
    height: 45px;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 700px;
    left: 450px;

    .copyright {
      margin-top: -10px;
    }

    .footer {
      font-family: "Poppins";
      color: #666666;
    }
  }
}
