@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600&display=swap');
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./font/Poppins/Poppins-Black.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 700;
    src: local('Poppins'), url(./font/Poppins/Poppins-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 200;
  src: local('Poppins'), url(./font/Poppins/Poppins-ExtraLight.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  src: local('Poppins'), url(./font/Poppins/Poppins-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  src: local('Poppins'), url(./font/Poppins/Poppins-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  src: local('Poppins'), url(./font/Poppins/Poppins-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  src: local('Poppins'), url(./font/Poppins/Poppins-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 100;
  src: local('Poppins'), url(./font/Poppins/Poppins-Thin.ttf) format('truetype');
}
