.layout {
  height: 100vh;
  display: flex;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.containers {
  height: 100%;
  width: 80vw;
  background: #fafafa;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.ant-skelton {
  width: 40%;
}
.sidebar {
  height: 100%;
  min-width: 20vw;
  position: relative;
  top: 0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-left: 1px solid grey;
  background-color: #000000;

  &__logo {
    display: flex;
    justify-content: center;
    padding: 16px 8px;
  }

  &__userprofile {
    display: flex;
    justify-content: center;
    .user {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      &__pic {
        display: flex;
        justify-content: center;
        img {
          width: 70px;
          height: 70px;
          border-radius: 100%;
        }
      }
      h1 {
        margin: 5px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        display: flex;
        align-items: center;
        color: #ffffff;
        width: 100%;
      }
      p {
        padding: 10px;
        background: rgba(244, 127, 32, 0.07);
        border-radius: 8px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #f47f20;
      }
    }
  }
}
.moduleContent {
  position: relative;
  overflow-y: auto;
  height: 65%;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  .top {
    &__nav-link {
      margin-top: 30px;
      &-li {
        &-nav {
          padding: 10px;
          // margin-left: 10px;
          &:hover {
            background: #1b1b1b;
            margin-bottom: 5px;
            margin-top: 10px;
          }
          .ic {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            color: #ffffff;
            display: flex;
            justify-content: left;
            cursor: pointer;
            margin-left: 20px;
          }
          .activeClass {
            color: #4895ef;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            display: flex;
            justify-content: left;
            cursor: pointer;
            margin-left: 20px;
          }
        }

        &-nav--active {
          padding: 10px 10px 10px 10px;
          background: #1b1b1b;
          // margin-left: 10px;
          border-left: 3px solid #4895ef;
          margin-top: 10px;
          .ic {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            color: #ffffff;
            display: flex;
            justify-content: left;
            cursor: pointer;
            margin-left: 20px;
            box-sizing: border-box;
          }
          .activeClass {
            color: #4895ef;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            display: flex;
            justify-content: left;
            cursor: pointer;
            margin-left: 20px;
          }
        }
        .sub-menu {
          text-align: left;
          margin-bottom: 20px;
          &--links {
            line-height: 32px;
            margin-top: 10px;
            margin-left: 40px;
            //   padding: 5px 20px;
            &:hover {
              background: #1b1b1b;
            }
            &_name {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 27px;
              color: #ffffff;
              display: block;
              padding: 10px 20px;
              // width: inherit;
              // height: inherit;
            }
          }
        }
      }
    }
  }
  .divider {
    margin: 24px;
    border: 0.5px solid #4895ef;
  }
 .jobs {
    &--btn {
      &-wrap {
        &-li {
          &-nav {
            padding: 10px;
            &:hover {
              background: #1b1b1b;
              margin-bottom: 5px;
              margin-top: 10px;
            }
            .ic {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 27px;
              color: #ffffff;
              display: flex;
              justify-content: left;
              cursor: pointer;
              margin-left: 20px;
            }
            .activeClass {
              color: #4895ef;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 27px;
              display: flex;
              justify-content: left;
              cursor: pointer;
              margin-left: 20px;
            }
          }
          &-nav--active {
            padding: 10px 10px 10px 10px;
            background: #1b1b1b;
            // margin-left: 0px;
            border-left: 3px solid #4895ef;
            margin-top: 10px;
            .ic {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 27px;
              color: #ffffff;
              display: flex;
              justify-content: left;
              cursor: pointer;
              margin-left: 20px;
              box-sizing: border-box;
            }
            .activeClass {
              color: #4895ef;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 27px;
              display: flex;
              justify-content: left;
              cursor: pointer;
              margin-left: 20px;
            }
          }
        }
      }
    }
  }



  .bottoms {
    &--btn {
      &-wrap {
        &-li {
          &-nav {
            padding: 10px;
            &:hover {
              background: #1b1b1b;
              margin-bottom: 5px;
              margin-top: 10px;
            }
            .ic {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 27px;
              color: #ffffff;
              display: flex;
              justify-content: left;
              cursor: pointer;
              margin-left: 20px;
            }
            .activeClass {
              color: #4895ef;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 27px;
              display: flex;
              justify-content: left;
              cursor: pointer;
              margin-left: 20px;
            }
          }
          &-nav--active {
            padding: 10px 10px 10px 10px;
            background: #1b1b1b;
            // margin-left: 0px;
            border-left: 3px solid #4895ef;
            margin-top: 10px;
            .ic {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 27px;
              color: #ffffff;
              display: flex;
              justify-content: left;
              cursor: pointer;
              margin-left: 20px;
              box-sizing: border-box;
            }
            .activeClass {
              color: #4895ef;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 27px;
              display: flex;
              justify-content: left;
              cursor: pointer;
              margin-left: 20px;
            }
          }
          .sub-menu {
            text-align: left;
            margin-bottom: 20px;
            &--links {
              line-height: 32px;
              margin-top: 10px;
              margin-left: 40px;
              &:hover {
                background: #1b1b1b;
              }
              &_name {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 27px;
                color: #ffffff;
                display: block;
                padding: 10px 20px;
              }
            }
          }
        }
      }
    }
  }
}

.logout {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
  button {
    background: #ffffff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #4895ef;
    padding: 2% 4%;
    border: 1px solid #fff;
    // margin-bottom: 5%;
    // margin-left: 14%;
    svg {
      margin-right: 10px;
    }
  }
}
