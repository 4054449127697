.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fafafa;
  border: none;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.user-area {
  display: flex;
  align-items: end;
  gap: 20px;
}
.sc-hAZoDl.kFa-DAq {
  width: 55px;
  height: 55px;
  border-radius: 100%;
  img {
    width: 100%;
    height: 100%;
  }
}
.react-tabs__tab-list {
  border-bottom: none !important;
  margin: 0 0 10px;
  padding: 0;
}
.react-tabs__tab--selected {
  background: transparent !important;
  border-color: transparent !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #4895ef !important;
  border-radius: 5px 5px 0 0;
}

ul.react-tabs__tab-list {
  background-color: #fff !important;
  padding: 12px 0 !important;
}
.dash-user-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #8c8c8c;
  white-space: nowrap;
}
.dash-user-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #8c8c8c;
  margin: 0;
  white-space: nowrap;
}
.dash-user-package {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #8c8c8c;
  white-space: nowrap;
}
.amount-area {
  p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #8c8c8c;
    white-space: nowrap;
  }
}
.status-area {
  p {
    padding: 10px;
    background: #32c769;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
  }
}
.status-approve {
  p {
    padding: 10px;
    background: #4895ef;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
  }
}
.status-rejected {
  p {
    padding: 10px;
    background: #db2128;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
  }
}
.user-table-wrap {
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
.task-dashboard {
  background-color: #f8f8f8;
  // height: 100vh;
  margin: 2% 2%;
  .action-header {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    margin: 40px 5px;
    justify-content: space-between;
    background-color: transparent;
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color: #666666;

    .delete-edit {
      display: flex;
      flex-direction: row;

      .delete-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 120px;
        outline: none;
        background-color: transparent;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        height: fit-content;

        &:hover {
          background-color: #db2128;
          outline: none;
        }

        &:focus {
          outline: none;
          background-color: #db2128;
        }
      }

      .edit-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 120px;
        margin: 0px 10px;
        outline: none;
        background-color: transparent;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        height: fit-content;

        &:hover {
          outline: none;
        }
      }
    }

    .filter-wrapper {
      position: relative;
      right: 3px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 180px;

      p {
        margin-top: 13px;
      }
    }
  }

  .table-header {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    margin: 40px 5px;
    justify-content: space-between;
    background-color: #fff;
    height: 75px;
    width: 99.5%;
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color: #8c8c8c;

    .status-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      width: 504px;
      height: fit-content;

      .pending-button {
        outline: none;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 234px;
        border: none;
        border-right: 0.1px solid #666666;
        cursor: pointer;

        span {
          background-color: #8c8c8c;
          border-radius: 100%;
          padding: 5px;
          color: white;
          width: 17%;
          height: 17%;
        }

        &:focus {
          color: #4895ef;

          span {
            background-color: #4895ef;
          }
        }
      }

      .approval-button {
        outline: none;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 154px;
        border: none;
        border-right: 0.1px solid #666666;
        cursor: pointer;

        span {
          background-color: #8c8c8c;
          border-radius: 100%;
          padding: 5px;
          color: white;
          width: 24%;
          height: 24%;
        }

        &:focus {
          color: #4895ef;

          span {
            background-color: #4895ef;
          }
        }
      }
    }

    .search-filter {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      width: 445px;

      &_searchbox {
        border: 0.5px solid #dfdfdf;
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding: 6px;
        height: 35px;
        width: 300px;
        box-sizing: border-box;
        .search-input {
          border: none;
          padding: 4px;
          width: 100%;
          font-family: "Poppins";
          font-size: 14px;
          line-height: 21px;
          font-style: normal;
          font-weight: 400;
          color: #8c8c8c;
          margin-left: 8px;
          background-color: transparent;

          &:focus {
            outline: none !important;
          }
        }
      }

      .filter-button {
        width: 100px;
        padding: 6px;
        height: 35px;
        outline: none;
        border: none;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        cursor: pointer;
        background-color: transparent;
      }
    }
  }

  .project-table {
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color: #aeaeae;
    margin-top: -30px;
    .user-area {
      display: flex;
      flex-direction: row;
      align-items: center;
      p {
        margin: 0 0 0 13px;
        white-space: nowrap;
      }
    }

    .amount-area {
      background-color: #dfdfdf;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70px;
      height: 30px;

      p {
        margin-top: 13px;
      }
    }

    .status-area {
      background-color: #32c769;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70px;
      height: 30px;
      color: #fff;

      p {
        margin-top: 13px;
      }
    }
  }
  .open-bid {
    display: flex;
    align-items: center;
    h1 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 27px;
      color: #666666;
      margin-right: 2%;
      margin-bottom: 0;
      white-space: nowrap;
    }
  }
  .description {
    &_page {
      // height: 79vh;
      // background-color: #fff;
      // border: 0.5px solid #DFDFDF;
      // border-radius: 10px;
    }
    &_wrap {
      border-bottom: 0.3px solid #dfdfdf;
      margin: 0px 25px;
      h1 {
        font-family: "Poppins";
        font-weight: 600;
        font-style: normal;
        font-size: 24px;
        line-height: 36px;
        color: #000000;
        padding: 18px 0px;
      }
      .require {
        font-family: "Poppins";
        font-weight: 600;
        font-style: normal;
        font-size: 24px;
        line-height: 36px;
        color: #000000;
        padding: 18px 0px 3px 0px;
      }
      .require-text {
        font-family: "Poppins";
        font-size: 18px;
        line-height: 27px;
        color: #666666;
      }
      &--preview {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // h1 {
        // }
        button {
          font-family: "Poppins";
          font-size: 18px;
          line-height: 27px;
          color: #4895ef;
          border: 0.5px solid #dfdfdf;
          border-radius: 5px;
          padding: 12px 24px;
          margin: 18px 0;
          border: 1px solid #dfdfdf;
          &:focus-visible {
            outline: none;
          }
          &:focus {
            outline: none;
          }
        }
        &__user {
          margin: 18px 25px;
        }
        &__userbox {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #fafafa;
          padding: 12px;
          .user-img {
            width: 70px;
            height: 70px;
            margin-right: 11px;
          }
          .img-profile {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .user-detail {
            h1 {
              font-family: "Poppins";
              font-size: 18px;
              line-height: 27px;
              display: flex;
              align-items: center;
              color: #000000;
            }
            p {
              font-family: "Poppins";
              font-size: 14px;
              line-height: 21px;
              display: flex;
              align-items: center;
              color: #666666;
              svg {
                margin-right: 6px;
                background: #4895ef;
                padding: 2px;
              }
            }
          }
          .user-button {
            button {
              background: #dfdfdf;
              border-radius: 5px;
              border: 1px solid #dfdfdf;
              padding: 10px 20px;
              font-family: "Poppins";
              font-size: 18px;
              line-height: 27px;
              display: flex;
              align-items: center;
              color: #ffffff;
              &:focus-visible {
                outline: none;
              }
              &:focus {
                outline: none;
              }
            }
          }
        }
        &__container {
          margin: 18px 25px;
          &-view {
            p {
              font-family: "Poppins";
              font-size: 24px;
              line-height: 36px;
              display: flex;
              align-items: center;
              color: #000000;
            }
          }
        }
        &__imgcontainer {
          margin: 18px 25px;
          &-stacks {
            display: flex;
            justify-content: space-between;
            .imgset {
              display: flex;
              flex-direction: column;
              .project {
                margin-right: 15px;
                margin-top: 10px;
                margin-bottom: 10px;
              }
              &--container {
                padding: 10px 15px;
                background: #ffffff;
                .tabs {
                  button {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    color: #666666;
                    border: none;
                    background-color: transparent;
                    margin-right: 15px;
                    cursor: pointer;
                    &:hover {
                      padding: 10px;
                      color: #0185e0;
                      border-radius: 8px;
                    }
                  }
                }
                .wrapper {
                  h1 {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    color: #252424;
                  }
                  p {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    color: #666666;
                  }
                  h2 {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    color: #242121;
                    margin: 0;
                  }
                  &__middle {
                    display: flex;
                    justify-content: space-between;
                    .right {
                      p {
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        display: flex;
                        align-items: center;
                        color: #797474;
                        &::before {
                          content: "";
                          display: inline-block;
                          width: 15px;
                          height: 15px;
                          -moz-border-radius: 7.5px;
                          -webkit-border-radius: 7.5px;
                          border-radius: 7.5px;
                          background: #15cf74;
                          margin-right: 10px;
                        }
                      }
                    }
                    .left {
                      h1 {
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 36px;
                        display: flex;
                        align-items: center;
                        color: #000000;
                      }
                    }
                  }
                }
              }
            }
            .big-img {
              .banner {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        &__category {
          margin: 18px 25px;
          &-box {
            display: flex;
            justify-content: space-between;
            .category-wrap {
              p {
                font-family: "Poppins";
                font-size: 14px;
                line-height: 150%;
                display: flex;
                align-items: center;
                text-align: justify;
                color: #666666;
                text-transform: uppercase;
                svg {
                  margin-right: 6px;
                }
              }
              span {
                font-family: "Poppins";
                font-size: 18px;
                line-height: 150%;
                display: flex;
                align-items: center;
                text-align: justify;
                color: #666666;
              }
            }
          }
        }
        &__projectbox {
          margin: 18px 25px;
          &-content {
            h1 {
              font-family: "Poppins";
              font-size: 18px;
              line-height: 27px;
              color: #000000;
            }
            .text {
              margin-top: 20px;
              p {
                font-family: "Poppins";
                font-size: 18px;
                line-height: 150%;
                text-align: justify;
                color: #666666;
                span {
                  color: #0185e0;
                }
              }
            }
          }
        }
        &__body {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .skills {
            margin: 18px 25px;
            h1 {
              font-family: "Poppins";
              font-size: 18px;
              line-height: 27px;
              color: #000000;
            }
            .skill-tag {
              display: flex;
              margin-top: 25px;
              .tag {
                background: #8c8c8c;
                border-radius: 50px;
                padding: 8px 19px;
                font-family: "Poppins";
                font-size: 14px;
                line-height: 21px;
                color: #ffffff;
                margin-right: 15px;
              }
            }
          }
          .keyword {
            margin: 25px 25px;
            h1 {
              font-family: "Poppins";
              font-size: 18px;
              line-height: 27px;
              color: #000000;
            }
            .skill-tag {
              display: flex;
              margin-top: 25px;
              .tag {
                background: #8c8c8c;
                border-radius: 50px;
                padding: 8px 19px;
                font-family: "Poppins";
                font-size: 14px;
                line-height: 21px;
                color: #ffffff;
                margin-right: 15px;
              }
            }
          }
        }
        &__faq {
          margin: 25px 25px;
          h1 {
            font-family: "Poppins";
            font-size: 18px;
            line-height: 27px;
            color: #000000;
            border-top: 0.3px solid #dfdfdf;
            border-bottom: 0.3px solid #dfdfdf;
            padding: 15px 0;
          }
        }
      }
    }
    &_textarea {
      margin-top: 30px;
      margin-left: 25px;
      margin-right: 25px;
      h1 {
        font-family: "Poppins";
        font-size: 14px;
        line-height: 21px;
        color: #666666;
        padding: 8px 0px;
      }
      .frequent {
        font-size: 24px;
        line-height: 15px;
        color: #666666;
        font-family: "Poppins";
        color: #666666;
        padding: 8px 0px;
      }
      .add {
        font-family: "Poppins";
        font-size: 14px;
        line-height: 21px;
        color: #666666;
        border-bottom: 0.3px solid #dfdfdf;
      }
    }

    &_contentarea {
      textarea {
        width: 100%;
        height: 150px;
        border: 0.3px solid #aeaeae;
        box-sizing: border-box;
        border-radius: 5px;
        caret-color: #aeaeae;
        padding: 12px 20px;
        font-size: 24px;
        line-height: 24px;
        &::placeholder {
          font-family: "Poppins";
          font-size: 24px;
          color: #aeaeae;
        }
        &:focus-visible {
          outline: none;
        }
      }
      p {
        font-family: "Poppins";
        font-size: 14px;
        line-height: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: #aeaeae;
      }
      .price-detail {
        font-family: "Poppins";
        font-size: 14px;
        line-height: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #aeaeae;
        svg {
          margin-right: 5px;
        }
      }
      .price-details {
        font-family: "Poppins";
        font-size: 14px;
        line-height: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #aeaeae;
        svg {
          margin-right: 5px;
        }
      }
    }
    &__gallery-wrapper {
      &--wrap {
        display: flex;
        justify-content: space-evenly;
      }
      &--galleryBox {
        .pic {
          margin: 20px 0;
          p {
            font-family: "Poppins";
            font-size: 14px;
            line-height: 21px;
            align-items: center;
            color: #8c8c8c;
            display: flex;
            justify-content: flex-end;
          }
        }
      }
      &--box {
        background: #f9f9f9;
        border-radius: 5px;
        width: 150px;
        height: 150px;
        margin-right: 15px;
        position: relative;
        h5 {
          font-family: "Poppins";
          font-size: 14px;
          line-height: 24px;
          color: #afafaf;
          white-space: nowrap;
          span {
            font-family: "Poppins";
            font-size: 12px;
            line-height: 24px;
            color: #afafaf;
            white-space: nowrap;
          }
        }
        .upload {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .img-position {
          label {
            display: flex;
            justify-content: center;
            .svg-text {
              display: flex;
              position: absolute;
              top: 42px;
            }
          }
        }
      }
    }
    &__dropdown {
      &-option-one {
        min-width: 320px;
        select {
          width: 100%;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background: transparent;
          background: transparent;
          background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
          background-repeat: no-repeat;
          background-position-x: 100%;
          background-position-y: 12px;
          border: 1px solid #dfdfdf;
          border-radius: 2px;
          margin-right: 2rem;
          padding: 1rem;
          padding-right: 2rem;
          &:focus-visible {
            outline: none;
          }
        }
        .input-wrap {
          width: 138px;
          input {
            width: 100%;
            margin-right: 2rem;
            padding: 1rem;
            padding-right: 2rem;
            display: inline-block;
            border: 1px solid #ccc;
            border-radius: 4px;
            box-sizing: border-box;
            &:focus-visible {
              outline: none;
            }
            &::placeholder {
              font-family: "Poppins";
              font-size: 14px;
              line-height: 21px;
              display: flex;
              align-items: center;
              color: #aeaeae;
            }
          }
        }
        .input-wraps {
          input {
            width: 100%;
            display: inline-block;
            box-sizing: border-box;
            border: 1px solid #dfdfdf;
            border-radius: 2px;
            margin-right: 2rem;
            padding: 1rem;
            padding-right: 2rem;
            &:focus-visible {
              outline: none;
            }
            &::placeholder {
              font-family: "Poppins";
              font-size: 14px;
              line-height: 21px;
              display: flex;
              align-items: center;
              color: #aeaeae;
            }
          }
        }
        .skill {
          font-family: "Poppins";
          font-size: 14px;
          display: flex;
          align-items: center;
          text-align: right;
          color: #aeaeae;
          justify-content: flex-end;
          padding: 12px 0px;
        }
      }
      &-option-bid {
        min-width: 320px;
        select {
          width: 100%;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background: transparent;
          background: transparent;
          background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
          background-repeat: no-repeat;
          background-position-x: 100%;
          background-position-y: 12px;
          border: 1px solid #dfdfdf;
          border-top: none;
          border-left: none;
          border-right: none;
          border-radius: 2px;
          margin-right: 2rem;
          padding: 1rem;
          padding-right: 2rem;
          &:focus-visible {
            outline: none;
          }
        }
      }
      &-wrapper {
        display: flex;
        justify-content: space-between;
      }
      &-wraper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3%;
      }
    }
    &__keywrap {
      input {
        width: 100%;
        padding: 12px 20px;
        margin: 8px 0;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        &:focus-visible {
          outline: none;
        }
        &::placeholder {
          font-family: "Poppins";
          font-size: 14px;
          line-height: 21px;
          display: flex;
          align-items: center;
          color: #aeaeae;
        }
      }
      .max {
        font-family: "Poppins";
        font-size: 14px;
        display: flex;
        align-items: center;
        text-align: right;
        color: #aeaeae;
        justify-content: flex-end;
        padding: 12px 0px;
      }
    }
    &__keywrap {
      margin-top: 12px;
    }
    &_btn {
      &-wrapper {
        display: flex;
        justify-content: space-between;
        margin: 14px 0px;
        .cancel {
          font-family: "Poppins";
          font-size: 18px;
          line-height: 27px;
          color: #8c8c8c;
          border: 0.5px solid #8c8c8c;
          border-radius: 5px;
          padding: 11px 19px;
          background-color: transparent;
          &:focus-visible {
            outline: none;
          }
        }
        .save {
          font-family: "Poppins";
          font-size: 18px;
          line-height: 27px;
          color: #ffffff;
          border: 0.5px solid #4895ef;
          border-radius: 5px;
          padding: 11px 19px;
          background: #4895ef;
          &:focus-visible {
            outline: none;
          }
        }
      }
    }
    &_subbtn {
      &-wrapper {
        display: flex;
        justify-content: flex-end;
        margin: 14px 25px;
        .cancel {
          font-family: "Poppins";
          font-size: 18px;
          line-height: 27px;
          color: #8c8c8c;
          border: 0.5px solid #8c8c8c;
          border-radius: 5px;
          padding: 11px 19px;
          background-color: transparent;
          margin: 0 20px;
          &:focus-visible {
            outline: none;
          }
        }
        .save {
          font-family: "Poppins";
          font-size: 18px;
          line-height: 27px;
          color: #ffffff;
          border: 0.5px solid #4895ef;
          border-radius: 5px;
          padding: 11px 19px;
          background: #4895ef;
          &:focus-visible {
            outline: none;
          }
        }
      }
    }
    &_link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: "Poppins";
      font-size: 18px;
      line-height: 27px;
      color: #4895ef;
      span {
        border: 1px solid rgba(72, 149, 239, 0.35);
        color: #4895ef;
        border-radius: 100%;
        width: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 37px;
        background: rgba(72, 149, 239, 0.35);
      }
      &:hover {
        font-family: "Poppins";
        font-size: 18px;
        line-height: 27px;
        color: #4895ef;
        span {
          border: 1px solid #4895ef;
          color: #ffffff;
          background: #4895ef;
        }
      }
    }
    &_viewoffer {
      padding: 10px 10px 10px 25px;
      background-color: #4895ef;
      h1 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: #ffffff;
        margin: 0;
      }
    }
    &_sellerprofile {
      .profile {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        margin-top: 20px;
        .img {
          width: 70px;
          height: 70px;
          border-radius: 100%;
          img {
            width: 100%;
          }
        }
        .name {
          h1 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
          }
          p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #0185e0;
          }
        }
        .star {
          background-color: #fff;
          padding: 5px 5px;
          span {
            margin-right: 5px;
          }
          fig {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #ffcc00;
          }
        }
      }
      .details {
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: flex-start;
        margin-top: 20px;
        .location {
          h1 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #313030;
          }
          p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #706c6c;
          }
        }
        .member {
          h1 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #313030;
          }
          p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #706c6c;
          }
        }
        .bttn {
          .contact {
            padding: 12px 38px;
            background: #0185e0;
            border-radius: 5px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #ffffff;
          }
        }
      }
      .bio {
        margin-top: 20px;
        padding: 10px;
        background: #ffffff;
        p {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: justify;
          color: #666666;
        }
      }
    }
  }
  &__tabs {
    .container {
      margin-top: 15px;
      padding: 10px 15px;
      background: #ffffff;
      .tabs {
        button {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #666666;
          border: none;
          background-color: transparent;
          margin-right: 15px;
          cursor: pointer;
          &:hover {
            padding: 10px;
            background: #4895ef;
            color: #ffffff;
            border-radius: 8px;
          }
        }
      }
    }
  }
}

.project-add {
  padding: 20px 10px;
  margin: 0px 30px;
  &--heading {
    background: #ffffff;
    border-radius: 5px 5px 0px 0px;
    display: flex;
    justify-content: space-between;
    padding: 12px 10px;
    align-items: center;
    &--title {
      h1 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #000000;
        margin-bottom: 0;
      }
    }
    &-btn {
      button {
        background: #000000;
        border-radius: 5px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        cursor: pointer;
        span {
          margin-right: 10px;
        }
      }
    }
  }
  &--form {
    margin: 30px 0px;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    &-row {
      display: flex;
      align-items: flex-start;
      gap: 80px;
      &--item {
        display: flex;
        flex-direction: column;
        width: 50%;
        gap: 15px;
        label {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: normal;
          color: #666666;
        }
        input {
          width: 100%;
          height: 60px;
          padding: 9px 25px;
          display: inline-block;
          background: #ffffff;
          border: 0.5px solid #dfdfdf;
          box-sizing: border-box;
          border-radius: 5px;
          color: #565656;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          &:focus {
            outline: none !important;
          }
          &::placeholder {
            color: #aeaeae;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        .nativeselect {
          width: 100%;
          height: 60px;
          padding: 9px 25px;
          background: #ffffff;
          border: 0.5px solid #dfdfdf;
          box-sizing: border-box;
          border-radius: 5px;
          color: #565656;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          display: flex;
          &:hover:not(.Mui-disabled, .Mui-error):before {
            border-bottom: none;
          }
          &:before {
            border-bottom: none;
          }
          &:after {
            border-bottom: none;
          }
          select {
            height: 45px;
            background: transparent;
          }
        }
        .cover {
          display: flex;
          flex-direction: column;
          gap: 15px;
          .coverImage {
            display: flex;
            input {
              width: 100%;
              height: 60px;
              padding: 9px 25px;
              display: inline-block;
              background: #ffffff;
              border: 0.5px solid #dfdfdf;
              box-sizing: border-box;
              border-radius: 5px 0px 0px 5px;
              color: #565656;
              font-family: "Poppins";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              &:focus {
                outline: none;
              }
              &::placeholder {
                color: #aeaeae;
                font-family: "Poppins";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
            .add-button {
              width: 200px;
              height: 60px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #fff;
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              border-radius: 0px 5px 5px 0px;
              border: 0.3px solid #aeaeae;
              background: #4895ef;
            }
          }
        }
        .selected {
          width: 100%;
          height: 60px;
          padding: 9px;
          background: #ffffff;
          color: #565656;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          input {
            &::placeholder {
              color: #aeaeae;
              font-family: "Poppins";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
        .selectCountry {
          background: white;
          border: 0.5px solid #dfdfdf;
          border-radius: 5px;
          height: 60px;
          padding: 9px 0px;
          .ReactFlagsSelect-module_selectBtn__19wW7 {
            border: none !important;
          }
          .ReactFlagsSelect-module_selectOptions__3LNBJ {
            max-height: 350px;
          }
        }
      }
      &--desc {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 15px;
        position: relative;
        #faq-label {
          position: absolute;
          margin-bottom: 20px;
        }
        label {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: normal;
          color: #666666;
        }
        input {
          width: 100%;
          height: 60px;
          padding: 9px 25px;
          display: inline-block;
          background: #ffffff;
          border: 0.5px solid #dfdfdf;
          box-sizing: border-box;
          border-radius: 5px;
          color: #565656;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          &:focus {
            outline: none !important;
          }
          &::placeholder {
            color: #aeaeae;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        textarea {
          width: 100%;
          height: 150px;
          padding: 9px 25px;
          display: inline-block;
          background: #ffffff;
          border: 0.5px solid #dfdfdf;
          box-sizing: border-box;
          border-radius: 5px;
          color: #565656;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          resize: none;
          &:focus {
            outline: none !important;
          }
          &::placeholder {
            color: #aeaeae;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        button {
          color: #4895ef;
          text-align: center;
          font-family: "Poppins";
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.36px;
          margin-left: auto;
        }
      }
    }
  }
  &--attachment {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: normal;
      color: #666666;
    }
    .left {
      display: flex;
      width: 100%;
      label {
        width: 100%;
        .svg-text {
          width: 100%;
          height: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px;
          background: #8c8c8c1f;
          border-radius: 5px;
          margin-right: 10px;
        }
      }
      .imageOne {
        width: 200px;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
        border-radius: 5px;
        border: 0.5px solid #8c8c8c1f;
        background-color: #f5f5f5;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
        video {
          width: 100%;
          height: 100%;
        }
      }
    }
    .svg-item {
      display: flex;
      padding: 16px;
      align-items: center;
      gap: 16px;
      border-radius: 8px;
      background: #ffffff;
      &-name {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
        p {
          color: #181818;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          margin: 0px;
        }
      }
    }
  }
  &--desc {
    margin-top: 20px;
    h1 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #000000;
      margin: 0;
    }
    label {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #000000;
      margin: 5px 0px;
    }
    #content {
      width: 100%;
      min-height: 92px;
      height: 100%;
      padding: 10px 10px 10px 15px;
      margin: 8px 0;
      display: inline-block;
      background: #fafafa;
      border: 0.5px solid #dfdfdf;
      box-sizing: border-box;
      border-radius: 5px;
      resize: none;
      &:focus {
        outline: none;
      }
      &::placeholder {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 27px;
        color: #dfdfdf;
      }
    }
  }
  &--switch {
    margin-top: 25px;
    &--heading {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 10px 25px;
      background: rgba(140, 140, 140, 0.3);
      border-radius: 15px 15px 0px 0px;
      .right {
        h1 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          color: #ffffff;
          margin-bottom: 0;
        }
      }
    }
    &--list {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 20px 25px;
      gap: 25px;
      border: 1px solid #dfdfdf;
      border-radius: 5px;
      flex-wrap: wrap;
      .list {
        &--wrap {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 25px 20px;
          gap: 10px;
          border: 0.5px solid #dfdfdf;
          border-radius: 10px;
          width: 210px;
          height: 150px;
          h1 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #666666;
            text-align: center;
          }
        }
      }
    }
  }
  &--btn {
    float: right;
    margin-top: 20px;
    // margin-right: 35px;
    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 24px;
      background: #4895ef;
      border-radius: 8px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.02em;
      color: #ffffff;
      margin-bottom: 20px;
    }
  }
  #imageButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px 10px 10px 15px;
    background: #4895ef;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: #ffffff;
    height: 100%;
  }
}
