
.user-area {
  display: flex;
  align-items: end;
  gap: 0 !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 12px 16px;
  cursor: pointer;
  transition: all 0.3s, visibility 0s;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #8C8C8C;
}
.ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  background-color: transparent;
  border: none;
  border-bottom: 0;
  border-radius: 2px;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: none; 
}
.order {
     margin: 2% 2%;
    .action-header {
      display: flex;
      flex-direction: row;
      position: relative;
      align-items: center;
      margin: 40px 5px;
      justify-content: space-between;
      background-color: transparent;
      font-family: "Poppins";
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      color: #666666;
  
      .delete-edit {
        display: flex;
        flex-direction: row;
  
        .delete-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          width: 120px;
          outline: none;
          background-color: transparent;
          border: none;
          cursor: pointer;
          border-radius: 5px;
          height: fit-content;
  
          &:hover {
            background-color: #db2128;
            outline: none;
          }
  
          &:focus {
            outline: none;
            background-color: #db2128;
          }
        }
  
        .edit-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          width: 120px;
          margin: 0px 10px;
          outline: none;
          background-color: transparent;
          border: none;
          cursor: pointer;
          border-radius: 5px;
          height: fit-content;
  
          &:hover {
            outline: none;
          }
        }
      }
  
      .filter-wrapper {
        position: relative;
        right: 3px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 180px;
  
        p {
          margin-top: 13px;
        }
      }
    }
  
    .table-header {
      display: flex;
      flex-direction: row;
      position: relative;
      align-items: center;
      margin: 40px 5px;
      justify-content: space-between;
      background-color: #fff;
      height: 75px;
      width: 99.5%;
      font-family: "Poppins";
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      color: #8C8C8C;
  
      .status-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 504px;
        height: fit-content;
  
        .pending-button {
          outline: none;
          background-color: transparent;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          width: 234px;
          border: none;
          border-right: 0.1px solid #666666;
          cursor: pointer;
  
          span {
            background-color: #8C8C8C;
            border-radius: 100%;
            padding: 5px;
            color: white;
            width: 17%;
            height: 17%;
          }
  
          &:focus {
            color: #4895EF;
  
            span {
              background-color: #4895EF;
            }
          }
        }
  
        .approval-button {
          outline: none;
          background-color: transparent;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          width: 154px;
          border: none;
          border-right: 0.1px solid #666666;
          cursor: pointer;
  
          span {
            background-color: #8C8C8C;
            border-radius: 100%;
            padding: 5px;
            color: white;
            width: 24%;
            height: 24%;
          }
  
          &:focus {
            color: #4895EF;
  
            span {
              background-color: #4895EF;
            }
          }
        }
      }
  
      .search-filter {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 445px;
  
  
        &_searchbox {
          border: 0.5px solid #DFDFDF;
          border-radius: 5px;
          display: flex;
          align-items: center;
          padding: 6px;
          height: 35px;
          width: 300px;
          box-sizing: border-box;
    
          .search-input {
            border: none;
            padding: 4px;
            width: 100%;
            font-family: "Poppins";
            font-size: 14px;
            line-height: 21px;
            font-style: normal;
            font-weight: 400;
            color: #8C8C8C;
            margin-left: 8px;
            background-color: transparent;
    
            &:focus {
              outline: none !important;
            }
          }
        }
  
        .filter-button {
          width: 100px;
          padding: 6px;
          height: 35px;
          outline: none;
          border: none;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          cursor: pointer;
          background-color: transparent;
        }
      }
    }
  
    .project-table {
      font-family: "Poppins";
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      color: #aeaeae;
      margin-top: -30px;
      .user-area {
        display: flex;
        flex-direction: row;
        align-items: center;
        p{
          margin: 0 0 0 13px;
          white-space: nowrap;
        }
      }
  
      .amount-area {
        background-color: #dfdfdf;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70px;
        height: 30px;
  
        p {
          margin-top: 13px;
        }
      }
  
      .status-area {
        background-color: #32c769;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70px;
        height: 30px;
        color: #fff;
  
        p {
          margin-top: 13px;
        }
      }
    }
    &__bottom{
      overflow: auto;
      &::-webkit-scrollbar {
       display: none;
   }
  }
    &_wrap{
      display: flex;
      justify-content: space-between;
      align-items: center;
      img{
        margin-right: 5%;
      }
      &-name{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #666666;
        white-space: nowrap;
        margin: 0;
      }
    }
  &__wrapper{
    // display:flex;
    // justify-content: space-between;
    // margin-top: 2%;
    .right{     
    background: #FFFFFF;
    border-radius: 5px;
    width: 100%;
    margin-right: 5%;
    height: 100%;
     &__header{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 18px;
      svg{
        margin-right: 2%;
      }
      h1{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 150%;
        text-align: justify;
        color: #000000;
        margin: 0;
      }
     }
     &__subheader{
       display: flex;
       justify-content: space-between;
       padding: 0 18px;
       h1{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        text-align: justify;
        color: #666666;
        margin: 0;
       }
       p{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #4895EF;
        background: #CCDFF5;
        padding: 8px 20px;
       }
     }
     &__top{
       display: flex;
       justify-content: space-between;
       align-items: center;
       padding: 0 18px;
       &-right{
         h1{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          color: #000000;
          margin: 0;
         }
         p{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 27px;
          color: #8C8C8C;
         }
       }
       &-left{
         h1{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          color: #000000;
          margin: 0;
         }
         p{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 27px;
          color: #8C8C8C;
         }
       }
     }
     &__middles{
      .subheader{
        display: flex;
        justify-content: space-between;
        padding: 12px 12px;
        align-items: center;
        h1{
         font-family: 'Poppins';
         font-style: normal;
         font-weight: 600;
         font-size: 18px;
         line-height: 150%;
         text-align: justify;
         color: #666666;
         margin: 0;
        }
        p{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #4895EF;
          background: #CCDFF5;
          padding: 8px 20px;
         }
     }
     p{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #000000;
      margin: 0;
      padding: 0 12px;
     }
     .duration{
       display: flex;
       justify-content: space-between;
       align-items: center;
       padding: 12px 12px;
       &-details{
         h4{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 27px;
          color: #666666;
          margin: 0;
          white-space: nowrap;
         }
         p{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 27px;
          color: #8C8C8C;
          margin: 0;
          white-space: nowrap;
          padding: 0;
         }
       }
     }
    }
     &__middle{
      padding: 0 18px;
       h1{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: #666666;
        margin: 0;
       }
       p{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #000000;
        margin: 0;
       }
       .img-slider{
         display: flex;
         align-items: center;
         margin-right: 2%;
         .card {
          box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
          width: 20;
        }
        h4{
          font-family: "Poppins";
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          color: #101010;
        }
       }
     }
     &__bottom{
      padding: 0 18px;
       h1{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #000000;
       }
     }
    &__btns{
      background: #fafafa;
      display: flex;
      justify-content: flex-end;
      padding-top: 3%;
      .cancel{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        text-align: justify;
        color: #FFFFFF;
        background: #000000;
        border-radius: 5px;
        margin-right: 2%;
        border: 2px solid #000000;
        padding: 8px 20px;
      }
      .extend{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        text-align: justify;
        color: #FFFFFF;
        background: #4895EF;
        border-radius: 5px;
        border: 2px solid #4895EF;
        padding: 8px 20px;
      }
    }
    }
    .left{
      background: #FFFFFF;
      &__header{
        h1{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;
          color: #666666;
          background: #FFFFFF;
          border-bottom: 0.3px solid #DFDFDF;
          padding: 8px 20px;
        }
      }
      &__top{
        .svg{
          display: flex;
          justify-content: center;
          padding: 20px 0;
        }
        .title{
          .heading{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            color: #000000;
            padding: 0 26px;
          }
          p{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #666666;
          }
          .date{
            font-family: 'Poppins';
            font-style: italic;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            text-transform: capitalize;
            color: #666666;
            text-align: center;
          }
          .svg{
            display: flex;
            justify-content: center;
            padding:20px 0;
          }
        }
      }
     &__dropdown{
       .mile{
         display: flex;
         justify-content: space-between;
         align-items: center;
         h1{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 150%;
          text-align: justify;
          color: #000000;
         }
         p{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
          color: #FC281C;
          background: #FEDBD7;
          padding: 8px;
         }
       }
       .reason{
         h2{
           svg{
             margin-right: 2%;
           }
           display: flex;
           align-items: center;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #000000;
          background: #AEAEAE;
          padding: 8px 8px;
         }
         p{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 150%;
          color: #666666;
         }
       }
      &-top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 18px;
        .right{
          h1{
           font-family: 'Poppins';
           font-style: normal;
           font-weight: 600;
           font-size: 18px;
           line-height: 27px;
           color: #000000;
           margin: 0;
          }
          p{
           font-family: 'Poppins';
           font-style: normal;
           font-weight: normal;
           font-size: 18px;
           line-height: 27px;
           color: #8C8C8C;
          }
        }
        .left{
          h1{
           font-family: 'Poppins';
           font-style: normal;
           font-weight: 600;
           font-size: 18px;
           line-height: 27px;
           color: #000000;
           margin: 0;
          }
          p{
           font-family: 'Poppins';
           font-style: normal;
           font-weight: normal;
           font-size: 18px;
           line-height: 27px;
           color: #8C8C8C;
          }
        }
      }
     }

    }
   }
  }
  