.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 0 !important;
  content: "";
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 10px !important;
  gap: 10px !important;
  width: 225px !important;
  height: 60px !important;
  background: #4895ef !important;
  border-radius: 5px !important;
  color: #fff !important;
}
.ant-tabs {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  // display: flex !important;
  // align-items: center!important;
  letter-spacing: 0.25px !important;
  color: #666666 !important;
}
.ant-tabs-ink-bar {
  position: absolute;
  background: transparent !important;
  pointer-events: none;
}
