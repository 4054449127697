.ant-table-thead > tr > th {
  position: relative;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
  white-space: nowrap;
}
.ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 2px 2px 0 0;
  border-collapse: separate;
  border-spacing: 0;
  background: #fff;
}
.ant-modal-header {
  background: #8c8c8c;
}
.ant-modal-title {
  margin: 0;
  color: #ffffff;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  word-wrap: break-word;
  font-family: "Poppins";
  font-style: normal;
}
.ant-modal-footer {
  display: flex;
  justify-content: space-between;
}
div#rc-tabs-0-tab-1,
div#rc-tabs-0-tab-2,
div#rc-tabs-0-tab-3,
div#rc-tabs-0-tab-4,
div#rc-tabs-0-tab-5,
div#rc-tabs-0-tab-6,
div#rc-tabs-0-tab-7 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  /* color: #4895EF; */
}
.user {
  // margin: 0 2%;
  &__header {
    margin-top: 3%;
    margin-bottom: 3%;
    &-row {
      display: flex;
      justify-content: space-between;
      background: #ffffff;
      padding: 10px 20px;
      align-items: center;
    }
  }
  &__top {
    display: flex;
    justify-content: space-between;
    margin-top: 1%;
    margin-bottom: 1%;
    &-btn {
      display: flex;
      .del {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #666666;
        border: none;
        background: none;
        display: flex;
        align-items: center;
        box-shadow: none;
        svg {
          margin-right: 10px;
        }
        &:hover {
          background-color: #db2128;
          color: #ffffff;
          path {
            fill: #ffffff;
          }
        }
      }
      .dels {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #666666;
        border: none;
        background: none;
        display: flex;
        align-items: center;
        margin: 0 25px;
        cursor: pointer;
        svg {
          margin-right: 10px;
        }
      }
    }
    &-filter {
      display: flex;
      justify-content: flex-end;
      .dell {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #666666;
        border: none;
        background: none;
        margin: 0 25px;
        white-space: nowrap;
        svg {
          margin-right: 10px;
        }
      }
      .dll {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #666666;
        border: none;
        background: #ffffff;
        padding: 8px 16px;
        display: flex;
        align-items: center;

        svg {
          margin-right: 10px;
        }
      }
    }
  }
  &__middle {
    display: flex;
    justify-content: flex-end;
    margin-top: 1%;
    margin-bottom: 1%;
    &-btns {
      display: flex;
      .all {
        font-family: "Poppins";
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #ffffff;
        background: #4895ef;
        border-radius: 5px;
        padding: 2% 19%;
        border: 1px solid #4895ef;
      }
      .job,
      .free,
      .business,
      .others {
        font-family: "Poppins";
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #666666;
        border-radius: 5px;
        padding: 2% 3%;
        background-color: transparent;
        border: 1px solid #666666;
        box-sizing: border-box;
        border-radius: 5px;
        white-space: nowrap;
        margin-left: 15px;
      }
    }
    &-search {
      .input {
        border: 0.3px solid #aeaeae;
        border-radius: 4px;
      }
    }
  }
  &__bottom {
    overflow: auto;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  &_name {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #666666;
    white-space: nowrap;
    margin: 0;
  }
  &_name1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #666666;
    white-space: pre-wrap;
    margin: 0;
  }
  &_avatar {
    border-radius: 100px;
  }
  &__button {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #ffffff;
    background: #4895ef;
    border: 1px solid #4895ef;
    box-sizing: border-box;
    border-radius: 5px;
  }
  &__active {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #32c769;
    background: #ffffff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 5px;
    &:hover {
      background: transparent;
      border: none;
    }
  }
  &__mailbutton {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-shadow: none;
    &:hover {
      color: #000000;
      background-color: transparent;
      border: none;
      box-shadow: none;
      text-shadow: none;
    }
  }
  &__btn {
    border: none;
    background-color: transparent;
    font-family: " Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #666666;
  }
  &__delete {
    &-heading {
      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: #666666;
      }
    }
    &-listing {
      ul {
        li {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          line-height: 36px;
          color: #666666;
        }
      }
      label {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #666666;
      }
    }
  }
  &__edit {
    margin-top: 3%;
    margin-bottom: 3%;
    &--heading {
      display: flex;
      justify-content: space-between;
      background: #dfdfdf;
      padding: 10px 20px;
      align-items: center;
      h1 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: #666666;
        margin: 0;
      }
      button {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        background: #000000;
        border-radius: 5px;
        padding: 8px 16px;
        display: flex;
        align-items: center;
        svg {
          margin-right: 10px;
        }
      }
      &-icon {
        display: flex;
        align-items: center;
        .right {
          margin-left: -101%;
          margin-right: 94%;
        }
      }
    }
    &--profile {
      display: flex;
      margin-top: 3%;
      .left {
        margin-right: 8%;
        .user-img {
          width: 250px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .user-btn {
          display: flex;
          align-items: center;
          margin-top: 5%;
          .message {
            background: #4895ef;
            border: 0.3px solid #4895ef;
            box-sizing: border-box;
            border-radius: 5px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 27px;
            color: #ffffff;
            padding: 8px 20px;
            white-space: nowrap;
            display: flex;
            align-items: center;
            svg {
              margin-right: 10px;
            }
            margin-right: 7%;
          }
          .mail {
            background: #000000;
            border-radius: 5px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #ffffff;
            display: flex;
            align-items: center;
            padding: 10px 20px;
            cursor: pointer;
            white-space: nowrap;
            display: flex;
            align-items: center;
            border: 0.3px solid #000000;
            svg {
              margin-right: 10px;
            }
          }
        }
      }
      .right {
        .contact {
          h1 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: #666666;
          }
          &__field {
            &-form {
              .field-one {
                margin-top: 2%;
                display: flex;
                justify-content: space-between;
                .one,
                .two {
                  min-width: 340px;
                  label {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    color: #666666;
                  }
                  #combo-box-demo {
                    border: none;
                  }
                  input[type="text"],
                  input[type="email"] {
                    width: 100%;
                    padding: 12px 20px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.3px solid #666666;
                    box-sizing: border-box;
                    border-radius: 5px;
                    &:focus {
                      outline: none;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: normal;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                }
                .one {
                  margin-right: 5%;
                }
              }
              .field-two {
                display: flex;
                justify-content: space-between;
                .first,
                .second {
                  margin-top: 2%;
                  min-width: 340px;
                  label {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    color: #666666;
                  }
                  input[type="date"],
                  input[type="email"],
                  input[type="number"],
                  input[type="text"] {
                    width: 100%;
                    padding: 12px 20px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.3px solid #666666;
                    box-sizing: border-box;
                    border-radius: 5px;
                    &:focus {
                      outline: none;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 300;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                  .list {
                    width: 100%;
                    padding: 12px 20px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.3px solid #666666;
                    box-sizing: border-box;
                    border-radius: 5px;
                    &:focus {
                      outline: none;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 300;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                  .datalist {
                    position: absolute;
                    background-color: white;
                    border: 1px solid blue;
                    border-radius: 0 0 5px 5px;
                    border-top: none;
                    font-family: sans-serif;
                    width: 350px;
                    padding: 5px;
                    max-height: 10rem;
                    overflow-y: auto;
                    option {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: normal;
                      font-size: 18px;
                      line-height: 27px;
                      color: #aeaeae;
                    }
                  }
                }
                .first {
                  margin-right: 5%;
                }
              }
              .field-three {
                display: flex;
                justify-content: space-between;
                .first,
                .second {
                  margin-top: 2%;
                  min-width: 340px;
                  label {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    color: #666666;
                  }
                  #combo-box-demo {
                    border: none;
                  }
                  input[type="text"],
                  select,
                  input[type="number"] {
                    width: 100%;
                    padding: 12px 20px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.3px solid #666666;
                    box-sizing: border-box;
                    border-radius: 5px;
                    &:focus {
                      outline: none;
                    }
                    /* Chrome, Safari, Edge, Opera */
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                      -webkit-appearance: none;
                      margin: 0;
                    }

                    /* Firefox */
                    input[type="number"] {
                      -moz-appearance: textfield;
                      appearance: textfield;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 300;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                  .list {
                    width: 100%;
                    padding: 12px 20px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.3px solid #666666;
                    box-sizing: border-box;
                    border-radius: 5px;
                    &:focus {
                      outline: none;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 300;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                }
                .first {
                  margin-right: 5%;
                }
              }
              .field-four {
                display: flex;
                justify-content: space-between;
                .first,
                .second {
                  margin-top: 2%;
                  min-width: 340px;
                  label {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    color: #666666;
                  }
                  input[type="date"] {
                    width: 100%;
                    padding: 12px 20px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.3px solid #666666;
                    box-sizing: border-box;
                    border-radius: 5px;
                    &:focus {
                      outline: none;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 300;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                  .list {
                    width: 100%;
                    padding: 12px 20px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.3px solid #666666;
                    box-sizing: border-box;
                    border-radius: 5px;
                    &:focus {
                      outline: none;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 300;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                }
                .first {
                  margin-right: 5%;
                }
              }
              .field-five {
                display: flex;
                justify-content: space-between;
                .first,
                .second {
                  margin-top: 2%;
                  min-width: 340px;
                  label {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    color: #666666;
                  }
                  input[type="text"] {
                    width: 100%;
                    padding: 12px 20px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.3px solid #666666;
                    box-sizing: border-box;
                    border-radius: 5px;
                    &:focus {
                      outline: none;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 300;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                  .list {
                    width: 100%;
                    padding: 12px 20px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.3px solid #666666;
                    box-sizing: border-box;
                    border-radius: 5px;
                    &:focus {
                      outline: none;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 300;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                }
                .first {
                  margin-right: 5%;
                }
              }
              .field-description {
                label {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 18px;
                  line-height: 27px;
                  color: #666666;
                }
                .text {
                  background: #fafafa;
                  border: 0.3px solid #aeaeae;
                  border-radius: 5px;
                  height: 133px;
                  min-width: 715px;
                  padding: 12px 20px;
                  margin: 8px 0;
                  &:focus-visible {
                    outline: none;
                  }
                }
                .skilltag {
                  margin: 2% 0;
                }
              }
            }
          }
        }
        .education {
          margin-top: 3%;
        }
        .experience {
          margin-top: 5%;
        }
      }
      .containers {
        margin-top: 1%;
        &_btn {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .reset {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: #666666;
            background: #ffffff;
            border: 0.3px solid #666666;
            box-sizing: border-box;
            border-radius: 5px;
            padding: 8px 26px;
            margin-right: 4%;
          }
          .save {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: #ffffff;
            background: #4895ef;
            border: 0.3px solid #4895ef;
            border-radius: 5px;
            padding: 8px 26px;
          }
        }
      }
    }

    &--adduser {
      &-button {
        margin-top: 2%;
        display: flex;
        justify-content: flex-end;
        button {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #ffffff;
          background: #4895ef;
          border: 0.3px solid #4895ef;
          border-radius: 5px;
          padding: 8px 26px;
        }
      }
      &-h1 {
        h1 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 27px;
          color: #000000;
          margin: 0;
        }
      }
      form {
        margin-top: 3%;
        .container {
          display: flex;
          justify-content: space-between;
          margin-top: 1%;
          .right,
          .left {
            //min-width for formik changed from 520px to 600px
            // width: 100%;
            min-width: 520px;
            #industryID,
            #subIndustryID {
              border: none;
            }
            label {
              font-family: "Poppins";
              font-style: normal;
              font-weight: normal;
              font-size: 18px;
              line-height: 27px;
              color: #666666;
            }
            .img-img {
              border: 1px solid #ddd;
              border-radius: 4px;
              padding: 5px;
              width: 150px;
            }
            input[type="text"],
            input[type="password"],
            input[type="email"],
            input[type="file"],
            input[type="date"],
            input[type="number"],
            select {
              width: 100%;
              padding: 12px 20px;
              margin: 8px 0;
              display: inline-block;
              background: #fafafa;
              border: 0.3px solid #666666;
              box-sizing: border-box;
              border-radius: 5px;
              &:focus {
                outline: none;
              }
              &::-webkit-input-placeholder {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 300;
                font-size: 18px;
                line-height: 27px;
                color: #666666;
                background: #fafafa;
              }
              /* Chrome, Safari, Edge, Opera */
              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }

              /* Firefox */
              input[type="number"] {
                -moz-appearance: textfield;
                appearance: textfield;
              }
              option {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 300;
                font-size: 18px;
                line-height: 27px;
                color: #666666;
              }
              select#user {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 300;
                font-size: 18px;
                line-height: 27px;
                color: #666666;
              }
            }
            textarea {
              font-family: "Poppins";
              font-style: normal;
              font-weight: normal;
              font-size: 18px;
              line-height: 27px;
              color: #666666;
              padding: 20px;
              background: #fafafa;
              border: 0.3px solid #aeaeae;
              border-radius: 5px;
              height: 100px;
              width: 100%;
              &:focus-visible {
                outline: none;
              }
            }
            .antd-select {
              width: 100%;
              // padding: 12px 20px;
              margin: 8px 0;
              display: inline-block;
              box-sizing: border-box;
              border-radius: 5px;
            }
          }
        }
        .containers {
          margin-top: 1%;
          &_btn {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .reset {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 27px;
              color: #666666;
              background: #ffffff;
              border: 0.3px solid #666666;
              box-sizing: border-box;
              border-radius: 5px;
              padding: 8px 26px;
              margin-right: 4%;
            }
            .save {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 27px;
              color: #ffffff;
              background: #4895ef;
              border: 0.3px solid #4895ef;
              border-radius: 5px;
              padding: 8px 26px;
            }
          }
        }
      }
    }
    &--userinfo {
      margin-top: 3%;
      background: #ffffff;
      border: 0.3px solid #dfdfdf;
      &-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 20px;
        .img-wrapper {
          width: 100px;
          margin-right: 2%;
          img {
            width: 100%;
          }
        }

        .user-details {
          h1 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 36px;
            line-height: 54px;
            color: #666666;
            margin: 0;
          }
          p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: #4895ef;
            margin: 0;
          }
          &-address {
            display: flex;
            align-items: center;
            p {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 27px;
              color: #666666;
              white-space: nowrap;
              display: flex;
              align-items: center;
              svg {
                margin-right: 5%;
              }
              margin-right: 7%;
            }
            .message {
              background: #4895ef;
              border: 0.3px solid #4895ef;
              box-sizing: border-box;
              border-radius: 5px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: normal;
              font-size: 18px;
              line-height: 27px;
              color: #ffffff;
              padding: 8px 20px;
              white-space: nowrap;
              display: flex;
              align-items: center;
              svg {
                margin-right: 10px;
              }
              margin-right: 7%;
            }
            .mail {
              background: #000000;
              border-radius: 5px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              color: #ffffff;
              display: flex;
              align-items: center;
              padding: 10px 20px;
              cursor: pointer;
              white-space: nowrap;
              display: flex;
              align-items: center;
              border: 0.3px solid #000000;
              svg {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
    &--tab {
      display: flex;
      flex-wrap: wrap;
      .tab-one,
      .tab-two,
      .tab-three,
      .tab-four {
        display: flex;
        align-items: baseline;
        margin-top: 1%;
        margin-right: 4%;
        .right {
          margin-right: 3%;
          h1 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: #666666;
            margin: 0;
            white-space: nowrap;
          }
          p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: #666666;
            margin: 0;
          }
          span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: #666666;
            margin: 0;
          }
        }
        .left {
          span {
            background: #dfdfdf;
            border-radius: 5px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: #666666;
            padding: 10px 20px;
            white-space: nowrap;
          }
        }
      }
      .konnect,
      .konnect-one,
      .konnect-two,
      .konnect-three,
      .konnect-four,
      .konnect-five {
        display: flex;
        align-items: baseline;
        margin-right: 5%;
        margin-top: 1%;
        &-img {
          width: 80px;
          margin-right: 5%;
          position: relative;
          img {
            width: 100%;
          }
          span {
            background: #32c769;
            width: 11px;
            height: 11px;
            border: 2px solid #0000;
            border-radius: 100%;
            position: absolute;
            right: 0px;
            bottom: 0;
            top: 35px;
          }
        }
        &-detail {
          margin-right: 5%;
          h1 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 36px;
            color: #666666;
            margin: 0;
            white-space: nowrap;
          }
          span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #4895ef;
          }
          p {
            display: flex;
            align-items: center;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #666666;
            svg {
              margin-right: 2%;
            }
          }
        }
        &-num {
          p {
            background: #dfdfdf;
            border-radius: 5px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: #666666;
            padding: 10px 20px;
            white-space: nowrap;
          }
        }
      }
      .skill,
      .skill-one,
      .skill-two {
        display: flex;
        align-items: center;
        margin-right: 5%;
        &_in {
          p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: #ffffff;
            background: #dfdfdf;
            border-radius: 5px;
            padding: 12px 24px;
          }
          ul {
            li {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 37px;
              color: #666666;
              list-style: none;
            }
          }
          .company {
            display: flex;
            align-items: center;
            margin-top: 15%;
            &__img {
              width: 100px;
              margin-right: 5%;
              img {
                width: 100%;
              }
            }

            &__details {
              h1 {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 27px;
                color: #666666;
              }
              p {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #666666;
                background-color: transparent;
                white-space: nowrap;
                padding: 0;
              }
            }
          }
        }
      }
    }
    &-child {
      margin: 3%;
      &--heading {
        display: flex;
        justify-content: space-between;
        background: #ffffff;
        padding: 10px 20px;
        align-items: center;
        h1 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 36px;
          color: #666666;
          margin: 0;
        }
      }
    }
    &-confirm {
      margin: 3%;
      &--heading {
        display: flex;
        justify-content: space-between;
        background: #dfdfdf;
        padding: 10px 20px;
        align-items: center;
        h1 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 36px;
          color: #666666;
          margin: 0;
        }
      }
    }
    &-attach {
      margin: 3%;
      &--heading {
        display: flex;
        justify-content: flex-start;
        border-bottom: 1px solid #dfdfdf;
        padding: 10px 20px;
        h1 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;
          color: #666666;
          margin: 0;
        }
      }
      .upload {
        margin-top: 2%;
        &-wrap {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
        }
      }
    }
    &---btn {
      margin-top: 2%;
      .btn-wrap {
        display: flex;
        justify-content: flex-end;
        .reject {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #666666;
          border: 0.3px solid #666666;
          box-sizing: border-box;
          border-radius: 5px;
          padding: 8px 20px;
          margin-right: 2%;
        }
        .approve {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #ffffff;
          background: #4895ef;
          border-radius: 5px;
          border: 0.3px solid #4895ef;
          padding: 8px 20px;
        }
      }
    }
  }
  &__addpurpose {
    h1 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 27px;
      color: #666666;
    }
    // textarea {
    //   font-family: "Poppins";
    //   font-style: normal;
    //   font-weight: normal;
    //   font-size: 18px;
    //   line-height: 27px;
    //   color: #666666;
    //   padding: 20px;
    //   background: #fafafa;
    //   border: 0.3px solid #aeaeae;
    //   border-radius: 5px;
    //   height: 100px;
    //   width: 100%;
    //   &:focus-visible {
    //     outline: none;
    //   }
    // }
    &--detail {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      margin-bottom: 2%;
      h1 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 27px;
        color: #666666;
      }
      input[type="text"],
      input[type="email"] {
        font-family: "Poppins";
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 27px;
        background: #fafafa;
        border: 0.3px solid #aeaeae;
        border-radius: 5px;
        color: #666666;
        min-width: 520px;
        width: 100%;
        padding: 12px 20px;
        margin: 8px 0;
        display: inline-block;
        box-sizing: border-box;
        &:focus {
          outline: none;
        }
        &::placeholder {
          font-family: "Poppins";
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 27px;
          color: #dfdfdf;
        }
      }
    }
    input[type="text"],
    input[type="email"] {
      width: 100%;
      padding: 12px 20px;
      margin: 8px 0;
      display: inline-block;
      background: #fafafa;
      border: 0.3px solid #aeaeae;
      box-sizing: border-box;
      border-radius: 5px;
      &:focus {
        outline: none;
      }
      &::placeholder {
        font-family: "Poppins";
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 27px;
        color: #dfdfdf;
      }
    }
    .img-attach {
      display: flex;
      justify-content: flex-start;
      .right,
      .left {
        width: 100px;
        height: auto;
        margin-right: 2%;
        img {
          width: 100%;
        }
      }
    }
    .search-wrapper {
      width: 450px;
      input[type="text"] {
        padding-left: 20px;
        width: 330px;
        height: 44px;
        right: 111px;
        padding: 10px 5px;
        float: left;
        font: bold 13px "lucida sans", "trebuchet MS", "Tahoma";
        border: 0;
        background: #fff;
        border-top-style: none;
        &:focus {
          outline: 0;
          background: #fff;
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.8) inset;
        }
        &::-webkit-input-placeholder {
          color: #999;
          font-weight: normal;
          font-style: italic;
          padding-left: 20px;
        }
      }
      button {
        margin-top: 15px;
        overflow: visible;
        position: relative;
        float: left;
        border: 0;
        padding: 0;
        cursor: pointer;
        height: 40px;
        width: 110px;
        font: 13px/40px "lucida sans", "trebuchet MS", "Tahoma";
        color: #fff;
        text-transform: uppercase;
        background: #198cff;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
      }
    }
    &--imagecontainer {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 30px;
      background-color: #ffffff;
      padding: 15px;
      .svg-text {
        width: 120px;
        height: 120px;
        border: 1px solid #dbdada;
        border-radius: 5px;
        background-color: #dbdada;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .imageOne {
        width: 120px;
        height: 120px;
        border: 1px solid #dbdada;
        border-radius: 5px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
      }
    }
  }
}
