.user {
  padding: 10px;
  &_heading {
    padding: 12px 25px;
    background: #ffffff;
    border-radius: 15px 15px 0px 0px;
    &-icon {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;
      img {
        width: 100%;
        max-width: 70px;
        height: auto;
        max-height: 70px;
        height: 70px;
        border-radius: 100%;
      }
    }
  }
  &-title {
    margin-top: 2%;
    h1 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 27px;
      color: #000000;
    }
  }
  &_header {
    &-wrap {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      margin-top: 20px;
      &-btn {
        display: flex;
        align-items: center;
        gap: 21px;
        button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 10px;
          gap: 10px;
          background: #4895ef;
          border-radius: 5px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
        }
        .edit {
          display: flex;
          align-items: center;
          .rclv {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #666666;
            margin: 0;
          }
        }
        .delet {
          display: flex;
          align-items: center;
          p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #666666;
            margin: 0;
          }
        }
      }
      &-search {
        display: flex;
        align-items: center;
        gap: 21px;
        .filter {
          display: flex;
          align-items: center;
          padding: 10px;
          background: #ffffff;
          border-radius: 5px;
          p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #666666;
            margin: 0;
            padding: 0 10px;
          }
        }
      }
    }
    &-tab {
      margin-top: 20px;
    }
  }
  &__edit {
    margin-top: 3%;
    margin-bottom: 3%;
    &--heading {
      display: flex;
      justify-content: space-between;
      background: #dfdfdf;
      padding: 10px 20px;
      align-items: center;
      h1 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: #666666;
        margin: 0;
      }
      button {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        background: #000000;
        border-radius: 5px;
        padding: 8px 16px;
        display: flex;
        align-items: center;
        svg {
          margin-right: 10px;
        }
      }
      &-icon {
        display: flex;
        align-items: center;
        .right {
          margin-left: -101%;
          margin-right: 94%;
        }
      }
    }
    &--profile {
      display: flex;
      margin-top: 3%;
      .left {
        margin-right: 8%;
        .user-img {
          width: 250px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .user-btn {
          display: flex;
          align-items: center;
          margin-top: 5%;
          .message {
            background: #4895ef;
            border: 0.3px solid #4895ef;
            box-sizing: border-box;
            border-radius: 5px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 27px;
            color: #ffffff;
            padding: 8px 20px;
            white-space: nowrap;
            display: flex;
            align-items: center;
            svg {
              margin-right: 10px;
            }
            margin-right: 7%;
          }
          .mail {
            background: #000000;
            border-radius: 5px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #ffffff;
            display: flex;
            align-items: center;
            padding: 10px 20px;
            cursor: pointer;
            white-space: nowrap;
            display: flex;
            align-items: center;
            border: 0.3px solid #000000;
            svg {
              margin-right: 10px;
            }
          }
        }
      }
      .right {
        .contact {
          h1 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: #666666;
          }
          &__field {
            &-form {
              .field-one {
                margin-top: 2%;
                display: flex;
                justify-content: space-between;
                .one,
                .two {
                  min-width: 340px;
                  label {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    color: #666666;
                  }
                  #combo-box-demo {
                    border: none;
                  }
                  input[type="text"],
                  input[type="email"] {
                    width: 100%;
                    padding: 12px 20px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.3px solid #666666;
                    box-sizing: border-box;
                    border-radius: 5px;
                    &:focus {
                      outline: none;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: normal;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                }
                .one {
                  margin-right: 5%;
                }
              }
              .field-two {
                display: flex;
                justify-content: space-between;
                .first,
                .second {
                  margin-top: 2%;
                  min-width: 340px;
                  label {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    color: #666666;
                  }
                  input[type="date"],
                  input[type="email"],
                  input[type="number"],
                  input[type="text"] {
                    width: 100%;
                    padding: 12px 20px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.3px solid #666666;
                    box-sizing: border-box;
                    border-radius: 5px;
                    &:focus {
                      outline: none;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 300;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                  .list {
                    width: 100%;
                    padding: 12px 20px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.3px solid #666666;
                    box-sizing: border-box;
                    border-radius: 5px;
                    &:focus {
                      outline: none;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 300;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                  .datalist {
                    position: absolute;
                    background-color: white;
                    border: 1px solid blue;
                    border-radius: 0 0 5px 5px;
                    border-top: none;
                    font-family: sans-serif;
                    width: 350px;
                    padding: 5px;
                    max-height: 10rem;
                    overflow-y: auto;
                    option {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: normal;
                      font-size: 18px;
                      line-height: 27px;
                      color: #aeaeae;
                    }
                  }
                }
                .first {
                  margin-right: 5%;
                }
              }
              .field-three {
                display: flex;
                justify-content: space-between;
                .first,
                .second {
                  margin-top: 2%;
                  min-width: 340px;
                  label {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    color: #666666;
                  }
                  #combo-box-demo {
                    border: none;
                  }
                  input[type="text"],
                  select,
                  input[type="number"] {
                    width: 100%;
                    padding: 12px 20px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.3px solid #666666;
                    box-sizing: border-box;
                    border-radius: 5px;
                    &:focus {
                      outline: none;
                    }
                    /* Chrome, Safari, Edge, Opera */
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                      -webkit-appearance: none;
                      margin: 0;
                    }

                    /* Firefox */
                    input[type="number"] {
                      -moz-appearance: textfield;
                      appearance: textfield;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 300;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                  .list {
                    width: 100%;
                    padding: 12px 20px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.3px solid #666666;
                    box-sizing: border-box;
                    border-radius: 5px;
                    &:focus {
                      outline: none;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 300;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                }
                .first {
                  margin-right: 5%;
                }
              }
              .field-four {
                display: flex;
                justify-content: space-between;
                .first,
                .second {
                  margin-top: 2%;
                  min-width: 340px;
                  label {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    color: #666666;
                  }
                  input[type="date"] {
                    width: 100%;
                    padding: 12px 20px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.3px solid #666666;
                    box-sizing: border-box;
                    border-radius: 5px;
                    &:focus {
                      outline: none;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 300;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                  .list {
                    width: 100%;
                    padding: 12px 20px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.3px solid #666666;
                    box-sizing: border-box;
                    border-radius: 5px;
                    &:focus {
                      outline: none;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 300;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                }
                .first {
                  margin-right: 5%;
                }
              }
              .field-five {
                display: flex;
                justify-content: space-between;
                .first,
                .second {
                  margin-top: 2%;
                  min-width: 340px;
                  label {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    color: #666666;
                  }
                  input[type="text"] {
                    width: 100%;
                    padding: 12px 20px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.3px solid #666666;
                    box-sizing: border-box;
                    border-radius: 5px;
                    &:focus {
                      outline: none;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 300;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                  .list {
                    width: 100%;
                    padding: 12px 20px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.3px solid #666666;
                    box-sizing: border-box;
                    border-radius: 5px;
                    &:focus {
                      outline: none;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 300;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                }
                .first {
                  margin-right: 5%;
                }
              }
              .field-description {
                label {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 18px;
                  line-height: 27px;
                  color: #666666;
                }
                .text {
                  background: #fafafa;
                  border: 0.3px solid #aeaeae;
                  border-radius: 5px;
                  height: 133px;
                  min-width: 715px;
                  padding: 12px 20px;
                  margin: 8px 0;
                  &:focus-visible {
                    outline: none;
                  }
                }
                .skilltag {
                  margin: 2% 0;
                }
              }
            }
          }
        }
        .education {
          margin-top: 3%;
        }
        .experience {
          margin-top: 5%;
        }
      }
      .containers {
        margin-top: 1%;
        &_btn {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .reset {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: #666666;
            background: #ffffff;
            border: 0.3px solid #666666;
            box-sizing: border-box;
            border-radius: 5px;
            padding: 8px 26px;
            margin-right: 4%;
          }
          .save {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: #ffffff;
            background: #4895ef;
            border: 0.3px solid #4895ef;
            border-radius: 5px;
            padding: 8px 26px;
          }
        }
      }
    }

    &--adduser {
      &-button {
        margin-top: 2%;
        display: flex;
        justify-content: flex-end;
        button {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #ffffff;
          background: #4895ef;
          border: 0.3px solid #4895ef;
          border-radius: 5px;
          padding: 8px 26px;
        }
      }
      &-h1 {
        h1 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 27px;
          color: #000000;
          margin: 0;
        }
      }
      form {
        margin-top: 3%;
        .container {
          display: flex;
          justify-content: space-between;
          margin-top: 1%;
          .right,
          .left {
            min-width: 520px;
            #industryID,
            #subIndustryID {
              border: none;
            }
            label {
              font-family: "Poppins";
              font-style: normal;
              font-weight: normal;
              font-size: 18px;
              line-height: 27px;
              color: #666666;
            }
            .img-img {
              border: 1px solid #ddd;
              border-radius: 4px;
              padding: 5px;
              width: 150px;
            }
            input[type="text"],
            input[type="password"],
            input[type="email"],
            input[type="file"],
            input[type="date"],
            input[type="number"],
            select {
              width: 100%;
              padding: 12px 20px;
              margin: 8px 0;
              display: inline-block;
              background: #fafafa;
              border: 0.3px solid #666666;
              box-sizing: border-box;
              border-radius: 5px;
              &:focus {
                outline: none;
              }
              &::-webkit-input-placeholder {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 300;
                font-size: 18px;
                line-height: 27px;
                color: #666666;
                background: #fafafa;
              }
              /* Chrome, Safari, Edge, Opera */
              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }

              /* Firefox */
              input[type="number"] {
                -moz-appearance: textfield;
                appearance: textfield;
              }
              option {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 300;
                font-size: 18px;
                line-height: 27px;
                color: #666666;
              }
              select#user {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 300;
                font-size: 18px;
                line-height: 27px;
                color: #666666;
              }
            }
            textarea {
              font-family: "Poppins";
              font-style: normal;
              font-weight: normal;
              font-size: 18px;
              line-height: 27px;
              color: #666666;
              padding: 20px;
              background: #fafafa;
              border: 0.3px solid #aeaeae;
              border-radius: 5px;
              height: 100px;
              width: 100%;
              &:focus-visible {
                outline: none;
              }
            }
            .antd-select {
              width: 100%;
              // padding: 12px 20px;
              margin: 8px 0;
              display: inline-block;
              box-sizing: border-box;
              border-radius: 5px;
            }
          }
        }
        .containers {
          margin-top: 1%;
          &_btn {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-right: 21px;
            .reset {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 27px;
              color: #666666;
              background: #ffffff;
              border: 0.3px solid #666666;
              box-sizing: border-box;
              border-radius: 5px;
              padding: 8px 26px;
              margin-right: 4%;
            }
            .save {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 27px;
              color: #ffffff;
              background: #4895ef;
              border: 0.3px solid #4895ef;
              border-radius: 5px;
              padding: 8px 26px;
            }
          }
        }
      }
    }
    &--flex {
      display: flex;
      &--userinfo {
        margin-top: 3%;
        background: #ffffff;
        border: 0.3px solid #dfdfdf;
        &-wrap {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 10px 20px;
          .img-wrapper {
            width: 100px;
            margin-right: 2%;
            img {
              width: 100%;
            }
          }
          .user-details {
            h1 {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 36px;
              line-height: 54px;
              color: #666666;
              margin: 0;
            }
            p {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 27px;
              color: #4895ef;
              margin: 0;
            }
            &-address {
              display: flex;
              align-items: center;
              p {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 27px;
                color: #666666;
                white-space: nowrap;
                display: flex;
                align-items: center;
                svg {
                  margin-right: 5%;
                }
                margin-right: 7%;
              }
              .message {
                background: #4895ef;
                border: 0.3px solid #4895ef;
                box-sizing: border-box;
                border-radius: 5px;
                font-family: "Poppins";
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 27px;
                color: #ffffff;
                padding: 8px 20px;
                white-space: nowrap;
                display: flex;
                align-items: center;
                svg {
                  margin-right: 10px;
                }
                margin-right: 7%;
              }
              .mail {
                background: #000000;
                border-radius: 5px;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #ffffff;
                display: flex;
                align-items: center;
                padding: 10px 20px;
                cursor: pointer;
                white-space: nowrap;
                display: flex;
                align-items: center;
                border: 0.3px solid #000000;
                svg {
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
      &--tab {
        display: flex;
        flex-wrap: wrap;
        .tab-one,
        .tab-two,
        .tab-three,
        .tab-four {
          display: flex;
          align-items: baseline;
          margin-top: 1%;
          margin-right: 4%;
          .right {
            margin-right: 3%;
            h1 {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 24px;
              line-height: 36px;
              color: #666666;
              margin: 0;
              white-space: nowrap;
            }
            p {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 27px;
              color: #666666;
              margin: 0;
            }
            span {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 27px;
              color: #666666;
              margin: 0;
            }
          }
          .left {
            span {
              background: #dfdfdf;
              border-radius: 5px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              color: #666666;
              padding: 10px 20px;
              white-space: nowrap;
            }
          }
        }
        .konnect,
        .konnect-one,
        .konnect-two,
        .konnect-three,
        .konnect-four,
        .konnect-five {
          display: flex;
          align-items: baseline;
          margin-right: 5%;
          margin-top: 1%;
          &-img {
            width: 80px;
            margin-right: 5%;
            position: relative;
            img {
              width: 100%;
            }
            span {
              background: #32c769;
              width: 11px;
              height: 11px;
              border: 2px solid #0000;
              border-radius: 100%;
              position: absolute;
              right: 0px;
              bottom: 0;
              top: 35px;
            }
          }
          &-detail {
            margin-right: 5%;
            h1 {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 36px;
              color: #666666;
              margin: 0;
              white-space: nowrap;
            }
            span {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              color: #4895ef;
            }
            p {
              display: flex;
              align-items: center;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              color: #666666;
              svg {
                margin-right: 2%;
              }
            }
          }
          &-num {
            p {
              background: #dfdfdf;
              border-radius: 5px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              color: #666666;
              padding: 10px 20px;
              white-space: nowrap;
            }
          }
        }
        .skill,
        .skill-one,
        .skill-two {
          display: flex;
          align-items: center;
          margin-right: 5%;
          &_in {
            p {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 24px;
              line-height: 36px;
              color: #ffffff;
              background: #dfdfdf;
              border-radius: 5px;
              padding: 12px 24px;
            }
            ul {
              li {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 37px;
                color: #666666;
                list-style: none;
              }
            }
            .company {
              display: flex;
              align-items: center;
              margin-top: 15%;
              &__img {
                width: 100px;
                margin-right: 5%;
                img {
                  width: 100%;
                }
              }

              &__details {
                h1 {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 18px;
                  line-height: 27px;
                  color: #666666;
                }
                p {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;
                  color: #666666;
                  background-color: transparent;
                  white-space: nowrap;
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
    &-child {
      margin: 3%;
      &--heading {
        display: flex;
        justify-content: space-between;
        background: #ffffff;
        padding: 10px 20px;
        align-items: center;
        h1 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 36px;
          color: #666666;
          margin: 0;
        }
      }
    }
    &-confirm {
      margin: 3%;
      &--heading {
        display: flex;
        justify-content: space-between;
        background: #dfdfdf;
        padding: 10px 20px;
        align-items: center;
        h1 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 36px;
          color: #666666;
          margin: 0;
        }
      }
    }
    &-attach {
      margin: 3%;
      &--heading {
        display: flex;
        justify-content: flex-start;
        border-bottom: 1px solid #dfdfdf;
        padding: 10px 20px;
        h1 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;
          color: #666666;
          margin: 0;
        }
      }
      .upload {
        margin-top: 2%;
        &-wrap {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
        }
      }
    }
    &---btn {
      margin-top: 2%;
      .btn-wrap {
        display: flex;
        justify-content: flex-end;
        .reject {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #666666;
          border: 0.3px solid #666666;
          box-sizing: border-box;
          border-radius: 5px;
          padding: 8px 20px;
          margin-right: 2%;
        }
        .approve {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #ffffff;
          background: #4895ef;
          border-radius: 5px;
          border: 0.3px solid #4895ef;
          padding: 8px 20px;
        }
      }
    }
  }
  &_klearning {
    &_wrapper {
      display: flex;
      width: 100%;
      padding: 24px 32px;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      border-radius: 12px;
      background: #fff;
      .heading {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        &_left {
          width: 100%;
          &_img {
            display: flex;
            align-items: center;
            gap: 16px;
            img {
              width: 100%;
              max-width: 70px;
              height: auto;
              max-height: 70px;
              height: 70px;
              border-radius: 100%;
            }
            &_desc {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              p {
                color: #f9a825;
                font-family: "Poppins";
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 125%;
              }
              h2 {
                color: #252d3c;
                font-family: "Poppins";
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%; /* 27px */
              }
              .details {
                display: flex;
                gap: 10px;
                p {
                  color: #f9a825;
                  font-family: Inter;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 125%; /* 15px */
                }
                h6 {
                  color: #344054;
                  font-family: "Poppins";
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 125%; /* 15px */
                }

                a {
                  overflow: hidden;
                  color: #4895ef;
                  font-family: "Poppins";
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 125%; /* 15px */
                  text-decoration-line: underline;
                }
              }
            }
          }
        }
        &_right {
          span {
            color: #fff;
            text-align: center;
            font-family: "Poppins";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 125%;
            border-radius: 2px;
            background: #f47f20;
            padding: 10px; /* 15px */
          }
        }
      }
      .sub-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .email,
        .phone,
        .addr {
          display: flex;
          gap: 10px;
          // align-items: center;
          &_em {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            h2 {
              color: #344054;
              font-family: "Poppins";
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 125%;
            }
            p {
              color: #a2a7b0;
              font-family: "Poppins";
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 125%; /* 15px */
            }
          }
        }
      }
    }
    &_wrap {
      display: flex;
      width: 100%;
      padding: 24px 32px;
      justify-content: center;
      align-items: flex-start;
      gap: 24px;
      flex: 1 0 0;
      border-radius: 12px;
      background: #fff;
      margin-top: 45px;
      .cate {
        display: flex;
        align-items: flex-start;
        justify-content: space-evenly;
        width: 100%;
        &_right {
          display: flex;
          align-items: flex-start;
          width: 100%;
          flex-direction: column;
          h1 {
            color: #252d3c;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%; /* 24px */
          }
          &_children {
            width: 100%;
            .list {
              display: flex;
              justify-content: flex-start;
              flex-wrap: wrap;
              gap: 10px;
              span {
                padding: var(--spacing-1, 8px) var(--spacing-2, 16px);
                gap: 20px;
                color: var(--Background-Text-secondary, var(--Grey-500, #344054));
                font-family: "Poppins";
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%; /* 21px */
                white-space: nowrap;
                border-radius: 37px;
                background: var(--Brand-quartenary, #f0f7fe);
              }
            }
          }
        }
        &_left {
          display: flex;
          align-items: flex-start;
          width: 100%;
          flex-direction: column;
          h1 {
            color: #252d3c;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%; /* 24px */
          }
          &_children {
            width: 100%;
            .list {
              display: flex;
              justify-content: flex-start;
              flex-wrap: wrap;
              gap: 10px;
              span {
                padding: var(--spacing-1, 8px) var(--spacing-2, 16px);
                gap: 20px;
                color: var(--Background-Text-secondary, var(--Grey-500, #344054));
                font-family: "Poppins";
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%; /* 21px */
                white-space: nowrap;
                border-radius: 37px;
                background: var(--Brand-quartenary, #f0f7fe);
              }
            }
          }
        }
      }
    }
    .buttons {
      margin-top: 20px;
      &_button {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 15px;
        .notaccpt {
          color: var(--Other-error, #e5151d);
          font-family: "Poppins";
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 125%; /* 15px */
          display: flex;
          padding: 11px 49px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: var(--Radius-sm, 4px);
          border: 1px solid var(--Other-error, #e5151d);
        }
        .accept {
          color: var(--Background-White, #fff);
          font-family: "Poppins";
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 125%; /* 15px */
          display: flex;
          padding: 11px 49px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: var(--Radius-sm, 4px);
          background: var(--Brand-primary, #4895ef);
        }
      }
    }
  }
}
.analytic {
  &_wrapper {
    margin-top: 24px;
    &_heading {
       width: 100%;
    display: flex;
    align-items: center;
    // justify-content: center;
    justify-content: flex-start;
    gap: 24px;
    flex-wrap: wrap;
      .earning {
        width: calc(25% - 18px);
        border-radius: 16px;
        border: .4px solid #dfdfdf;
        background: #fff;
        box-shadow: 0 7.56px 47.88px 0 rgba(0, 0, 0, .04);
        padding: 20px 28px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        // gap: 10px;
        border-radius: 12px;
        h1 {
          color: var(--Background-Text-secondary, var(--Grey-500, #344054));
          font-family: "Poppins";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 125%; /* 15px */
        }
        &_wrap {
          h1 {
            color: var(--Background-Text-primary, var(--Grey-700, #252d3c));
            font-family: "Poppins";
            font-size: 24px;
            font-style: normal;
            font-weight: 900;
            line-height: 150%; /* 36px */
          }
        }
      }
      .instructor {
         width: calc(25% - 18px);
        border-radius: 16px;
        border: .4px solid #dfdfdf;
        background: #fff;
        box-shadow: 0 7.56px 47.88px 0 rgba(0, 0, 0, .04);
        padding: 20px 28px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        h1 {
          color: var(--Background-Text-secondary, var(--Grey-500, #344054));
          font-family: "Poppins";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 125%; /* 15px */
        }
        &_wrap {
          h1 {
            color: var(--Background-Text-primary, var(--Grey-700, #252d3c));
            font-family: "Poppins";
            font-size: 24px;
            font-style: normal;
            font-weight: 900;
            line-height: 150%; /* 36px */
          }
        }
      }
      .course {
      width: calc(25% - 18px);
        border-radius: 16px;
        border: .4px solid #dfdfdf;
        background: #fff;
        box-shadow: 0 7.56px 47.88px 0 rgba(0, 0, 0, .04);
        padding: 20px 28px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        h1 {
          color: var(--Background-Text-secondary, var(--Grey-500, #344054));
          font-family: "Poppins";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 125%; /* 15px */
        }
        &_wrap {
          h1 {
            color: var(--Background-Text-primary, var(--Grey-700, #252d3c));
            font-family: "Poppins";
            font-size: 24px;
            font-style: normal;
            font-weight: 900;
            line-height: 150%; /* 36px */
          }
        }
      }
      .enrol {
        width: calc(25% - 18px);
        border-radius: 16px;
        border: .4px solid #dfdfdf;
        background: #fff;
        box-shadow: 0 7.56px 47.88px 0 rgba(0, 0, 0, .04);
        padding: 20px 28px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        h1 {
          color: var(--Background-Text-secondary, var(--Grey-500, #344054));
          font-family: "Poppins";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 125%; /* 15px */
        }
        &_wrap {
          h1 {
            color: var(--Background-Text-primary, var(--Grey-700, #252d3c));
            font-family: "Poppins";
            font-size: 24px;
            font-style: normal;
            font-weight: 900;
            line-height: 150%; /* 36px */
          }
        }
      }
    }
  }
}

.log {
  padding: 10px;
  &_heading {
    padding: 12px 25px;
    background: #ffffff;
    border-radius: 15px 15px 0px 0px;
    &-icon {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;
      img {
        width: 100%;
        max-width: 70px;
        height: auto;
        max-height: 70px;
        height: 70px;
        border-radius: 100%;
      }
    }
  }
  &-title {
    margin-top: 2%;
    display: flex;
    justify-content: space-between;
    h1 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 27px;
      color: #000000;
    }
    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px;
      gap: 10px;
      background: #4895ef;
      border-radius: 5px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
    }
  }
  &_header {
    &-wrap {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      margin-top: 20px;
      &-btn {
        display: flex;
        align-items: center;
        gap: 21px;
        button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 10px;
          gap: 10px;
          background: #4895ef;
          border-radius: 5px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
        }
        .edit {
          display: flex;
          align-items: center;
          .rclv {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #666666;
            margin: 0;
          }
        }
        .delet {
          display: flex;
          align-items: center;
          p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #666666;
            margin: 0;
          }
        }
      }
      &-search {
        display: flex;
        align-items: center;
        gap: 21px;
        .filter {
          display: flex;
          align-items: center;
          padding: 10px;
          background: #ffffff;
          border-radius: 5px;
          p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #666666;
            margin: 0;
            padding: 0 10px;
          }
        }
      }
    }
    &-tab {
      margin-top: 20px;
    }
  }
  &__edit {
    margin-top: 3%;
    margin-bottom: 3%;
    &--heading {
      display: flex;
      justify-content: space-between;
      background: #dfdfdf;
      padding: 10px 20px;
      align-items: center;
      h1 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: #666666;
        margin: 0;
      }
      button {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        background: #000000;
        border-radius: 5px;
        padding: 8px 16px;
        display: flex;
        align-items: center;
        svg {
          margin-right: 10px;
        }
      }
      &-icon {
        display: flex;
        align-items: center;
        .right {
          margin-left: -101%;
          margin-right: 94%;
        }
      }
    }
    &--profile {
      display: flex;
      margin-top: 3%;
      .left {
        margin-right: 8%;
        .user-img {
          width: 250px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .user-btn {
          display: flex;
          align-items: center;
          margin-top: 5%;
          .message {
            background: #4895ef;
            border: 0.3px solid #4895ef;
            box-sizing: border-box;
            border-radius: 5px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 27px;
            color: #ffffff;
            padding: 8px 20px;
            white-space: nowrap;
            display: flex;
            align-items: center;
            svg {
              margin-right: 10px;
            }
            margin-right: 7%;
          }
          .mail {
            background: #000000;
            border-radius: 5px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #ffffff;
            display: flex;
            align-items: center;
            padding: 10px 20px;
            cursor: pointer;
            white-space: nowrap;
            display: flex;
            align-items: center;
            border: 0.3px solid #000000;
            svg {
              margin-right: 10px;
            }
          }
        }
      }
      .right {
        .contact {
          h1 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: #666666;
          }
          &__field {
            &-form {
              .field-one {
                margin-top: 2%;
                display: flex;
                justify-content: space-between;
                .one,
                .two {
                  min-width: 340px;
                  label {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    color: #666666;
                  }
                  #combo-box-demo {
                    border: none;
                  }
                  input[type="text"],
                  input[type="email"] {
                    width: 100%;
                    padding: 12px 20px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.3px solid #666666;
                    box-sizing: border-box;
                    border-radius: 5px;
                    &:focus {
                      outline: none;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: normal;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                }
                .one {
                  margin-right: 5%;
                }
              }
              .field-two {
                display: flex;
                justify-content: space-between;
                .first,
                .second {
                  margin-top: 2%;
                  min-width: 340px;
                  label {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    color: #666666;
                  }
                  input[type="date"],
                  input[type="email"],
                  input[type="number"],
                  input[type="text"] {
                    width: 100%;
                    padding: 12px 20px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.3px solid #666666;
                    box-sizing: border-box;
                    border-radius: 5px;
                    &:focus {
                      outline: none;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 300;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                  .list {
                    width: 100%;
                    padding: 12px 20px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.3px solid #666666;
                    box-sizing: border-box;
                    border-radius: 5px;
                    &:focus {
                      outline: none;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 300;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                  .datalist {
                    position: absolute;
                    background-color: white;
                    border: 1px solid blue;
                    border-radius: 0 0 5px 5px;
                    border-top: none;
                    font-family: sans-serif;
                    width: 350px;
                    padding: 5px;
                    max-height: 10rem;
                    overflow-y: auto;
                    option {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: normal;
                      font-size: 18px;
                      line-height: 27px;
                      color: #aeaeae;
                    }
                  }
                }
                .first {
                  margin-right: 5%;
                }
              }
              .field-three {
                display: flex;
                justify-content: space-between;
                .first,
                .second {
                  margin-top: 2%;
                  min-width: 340px;
                  label {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    color: #666666;
                  }
                  #combo-box-demo {
                    border: none;
                  }
                  input[type="text"],
                  select,
                  input[type="number"] {
                    width: 100%;
                    padding: 12px 20px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.3px solid #666666;
                    box-sizing: border-box;
                    border-radius: 5px;
                    &:focus {
                      outline: none;
                    }
                    /* Chrome, Safari, Edge, Opera */
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                      -webkit-appearance: none;
                      margin: 0;
                    }

                    /* Firefox */
                    input[type="number"] {
                      -moz-appearance: textfield;
                      appearance: textfield;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 300;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                  .list {
                    width: 100%;
                    padding: 12px 20px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.3px solid #666666;
                    box-sizing: border-box;
                    border-radius: 5px;
                    &:focus {
                      outline: none;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 300;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                }
                .first {
                  margin-right: 5%;
                }
              }
              .field-four {
                display: flex;
                justify-content: space-between;
                .first,
                .second {
                  margin-top: 2%;
                  min-width: 340px;
                  label {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    color: #666666;
                  }
                  input[type="date"] {
                    width: 100%;
                    padding: 12px 20px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.3px solid #666666;
                    box-sizing: border-box;
                    border-radius: 5px;
                    &:focus {
                      outline: none;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 300;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                  .list {
                    width: 100%;
                    padding: 12px 20px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.3px solid #666666;
                    box-sizing: border-box;
                    border-radius: 5px;
                    &:focus {
                      outline: none;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 300;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                }
                .first {
                  margin-right: 5%;
                }
              }
              .field-five {
                display: flex;
                justify-content: space-between;
                .first,
                .second {
                  margin-top: 2%;
                  min-width: 340px;
                  label {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    color: #666666;
                  }
                  input[type="text"] {
                    width: 100%;
                    padding: 12px 20px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.3px solid #666666;
                    box-sizing: border-box;
                    border-radius: 5px;
                    &:focus {
                      outline: none;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 300;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                  .list {
                    width: 100%;
                    padding: 12px 20px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.3px solid #666666;
                    box-sizing: border-box;
                    border-radius: 5px;
                    &:focus {
                      outline: none;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 300;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                }
                .first {
                  margin-right: 5%;
                }
              }
              .field-description {
                label {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 18px;
                  line-height: 27px;
                  color: #666666;
                }
                .text {
                  background: #fafafa;
                  border: 0.3px solid #aeaeae;
                  border-radius: 5px;
                  height: 133px;
                  min-width: 715px;
                  padding: 12px 20px;
                  margin: 8px 0;
                  &:focus-visible {
                    outline: none;
                  }
                }
                .skilltag {
                  margin: 2% 0;
                }
              }
            }
          }
        }
        .education {
          margin-top: 3%;
        }
        .experience {
          margin-top: 5%;
        }
      }
      .containers {
        margin-top: 1%;
        &_btn {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .reset {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: #666666;
            background: #ffffff;
            border: 0.3px solid #666666;
            box-sizing: border-box;
            border-radius: 5px;
            padding: 8px 26px;
            margin-right: 4%;
          }
          .save {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: #ffffff;
            background: #4895ef;
            border: 0.3px solid #4895ef;
            border-radius: 5px;
            padding: 8px 26px;
          }
        }
      }
    }

    &--adduser {
      &-button {
        margin-top: 2%;
        display: flex;
        justify-content: flex-end;
        button {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #ffffff;
          background: #4895ef;
          border: 0.3px solid #4895ef;
          border-radius: 5px;
          padding: 8px 26px;
        }
      }
      &-h1 {
        h1 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 27px;
          color: #000000;
          margin: 0;
        }
      }
      form {
        margin-top: 3%;
        .container {
          display: flex;
          justify-content: space-between;
          margin-top: 1%;
          .right,
          .left {
            //min-width for formik changed from 520px to 600px
            // width: 100%;
            min-width: 520px;
            #industryID,
            #subIndustryID {
              border: none;
            }
            label {
              font-family: "Poppins";
              font-style: normal;
              font-weight: normal;
              font-size: 18px;
              line-height: 27px;
              color: #666666;
            }
            .img-img {
              border: 1px solid #ddd;
              border-radius: 4px;
              padding: 5px;
              width: 150px;
            }
            input[type="text"],
            input[type="password"],
            input[type="email"],
            input[type="file"],
            input[type="date"],
            input[type="number"],
            select {
              width: 100%;
              padding: 12px 20px;
              margin: 8px 0;
              display: inline-block;
              background: #fafafa;
              border: 0.3px solid #666666;
              box-sizing: border-box;
              border-radius: 5px;
              &:focus {
                outline: none;
              }
              &::-webkit-input-placeholder {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 300;
                font-size: 18px;
                line-height: 27px;
                color: #666666;
                background: #fafafa;
              }
              /* Chrome, Safari, Edge, Opera */
              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }

              /* Firefox */
              input[type="number"] {
                -moz-appearance: textfield;
                appearance: textfield;
              }
              option {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 300;
                font-size: 18px;
                line-height: 27px;
                color: #666666;
              }
              select#user {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 300;
                font-size: 18px;
                line-height: 27px;
                color: #666666;
              }
            }
            textarea {
              font-family: "Poppins";
              font-style: normal;
              font-weight: normal;
              font-size: 18px;
              line-height: 27px;
              color: #666666;
              padding: 20px;
              background: #fafafa;
              border: 0.3px solid #aeaeae;
              border-radius: 5px;
              height: 100px;
              width: 100%;
              &:focus-visible {
                outline: none;
              }
            }
            .antd-select {
              width: 100%;
              // padding: 12px 20px;
              margin: 8px 0;
              display: inline-block;
              box-sizing: border-box;
              border-radius: 5px;
            }
          }
        }
        .containers {
          margin-top: 1%;
          &_btn {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-right: 21px;
            .reset {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 27px;
              color: #666666;
              background: #ffffff;
              border: 0.3px solid #666666;
              box-sizing: border-box;
              border-radius: 5px;
              padding: 8px 26px;
              margin-right: 4%;
            }
            .save {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 27px;
              color: #ffffff;
              background: #4895ef;
              border: 0.3px solid #4895ef;
              border-radius: 5px;
              padding: 8px 26px;
            }
          }
        }
      }
    }
    &--flex {
      display: flex;
      &--userinfo {
        margin-top: 3%;
        background: #ffffff;
        border: 0.3px solid #dfdfdf;
        &-wrap {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 10px 20px;
          .img-wrapper {
            width: 100px;
            margin-right: 2%;
            img {
              width: 100%;
            }
          }

          .user-details {
            h1 {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 36px;
              line-height: 54px;
              color: #666666;
              margin: 0;
            }
            p {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 27px;
              color: #4895ef;
              margin: 0;
            }
            &-address {
              display: flex;
              align-items: center;
              p {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 27px;
                color: #666666;
                white-space: nowrap;
                display: flex;
                align-items: center;
                svg {
                  margin-right: 5%;
                }
                margin-right: 7%;
              }
              .message {
                background: #4895ef;
                border: 0.3px solid #4895ef;
                box-sizing: border-box;
                border-radius: 5px;
                font-family: "Poppins";
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 27px;
                color: #ffffff;
                padding: 8px 20px;
                white-space: nowrap;
                display: flex;
                align-items: center;
                svg {
                  margin-right: 10px;
                }
                margin-right: 7%;
              }
              .mail {
                background: #000000;
                border-radius: 5px;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #ffffff;
                display: flex;
                align-items: center;
                padding: 10px 20px;
                cursor: pointer;
                white-space: nowrap;
                display: flex;
                align-items: center;
                border: 0.3px solid #000000;
                svg {
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
      &--tab {
        display: flex;
        flex-wrap: wrap;
        .tab-one,
        .tab-two,
        .tab-three,
        .tab-four {
          display: flex;
          align-items: baseline;
          margin-top: 1%;
          margin-right: 4%;
          .right {
            margin-right: 3%;
            h1 {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 24px;
              line-height: 36px;
              color: #666666;
              margin: 0;
              white-space: nowrap;
            }
            p {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 27px;
              color: #666666;
              margin: 0;
            }
            span {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 27px;
              color: #666666;
              margin: 0;
            }
          }
          .left {
            span {
              background: #dfdfdf;
              border-radius: 5px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              color: #666666;
              padding: 10px 20px;
              white-space: nowrap;
            }
          }
        }
        .konnect,
        .konnect-one,
        .konnect-two,
        .konnect-three,
        .konnect-four,
        .konnect-five {
          display: flex;
          align-items: baseline;
          margin-right: 5%;
          margin-top: 1%;
          &-img {
            width: 80px;
            margin-right: 5%;
            position: relative;
            img {
              width: 100%;
            }
            span {
              background: #32c769;
              width: 11px;
              height: 11px;
              border: 2px solid #0000;
              border-radius: 100%;
              position: absolute;
              right: 0px;
              bottom: 0;
              top: 35px;
            }
          }
          &-detail {
            margin-right: 5%;
            h1 {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 36px;
              color: #666666;
              margin: 0;
              white-space: nowrap;
            }
            span {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              color: #4895ef;
            }
            p {
              display: flex;
              align-items: center;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              color: #666666;
              svg {
                margin-right: 2%;
              }
            }
          }
          &-num {
            p {
              background: #dfdfdf;
              border-radius: 5px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              color: #666666;
              padding: 10px 20px;
              white-space: nowrap;
            }
          }
        }
        .skill,
        .skill-one,
        .skill-two {
          display: flex;
          align-items: center;
          margin-right: 5%;
          &_in {
            p {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 24px;
              line-height: 36px;
              color: #ffffff;
              background: #dfdfdf;
              border-radius: 5px;
              padding: 12px 24px;
            }
            ul {
              li {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 37px;
                color: #666666;
                list-style: none;
              }
            }
            .company {
              display: flex;
              align-items: center;
              margin-top: 15%;
              &__img {
                width: 100px;
                margin-right: 5%;
                img {
                  width: 100%;
                }
              }

              &__details {
                h1 {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 18px;
                  line-height: 27px;
                  color: #666666;
                }
                p {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;
                  color: #666666;
                  background-color: transparent;
                  white-space: nowrap;
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
    &-child {
      margin: 3%;
      &--heading {
        display: flex;
        justify-content: space-between;
        background: #ffffff;
        padding: 10px 20px;
        align-items: center;
        h1 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 36px;
          color: #666666;
          margin: 0;
        }
      }
    }
    &-confirm {
      margin: 3%;
      &--heading {
        display: flex;
        justify-content: space-between;
        background: #dfdfdf;
        padding: 10px 20px;
        align-items: center;
        h1 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 36px;
          color: #666666;
          margin: 0;
        }
      }
    }
    &-attach {
      margin: 3%;
      &--heading {
        display: flex;
        justify-content: flex-start;
        border-bottom: 1px solid #dfdfdf;
        padding: 10px 20px;
        h1 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;
          color: #666666;
          margin: 0;
        }
      }
      .upload {
        margin-top: 2%;
        &-wrap {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
        }
      }
    }
    &---btn {
      margin-top: 2%;
      .btn-wrap {
        display: flex;
        justify-content: flex-end;
        .reject {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #666666;
          border: 0.3px solid #666666;
          box-sizing: border-box;
          border-radius: 5px;
          padding: 8px 20px;
          margin-right: 2%;
        }
        .approve {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #ffffff;
          background: #4895ef;
          border-radius: 5px;
          border: 0.3px solid #4895ef;
          padding: 8px 20px;
        }
      }
    }
  }
}
