.activity {
  padding: 20px 10px;
  &-wrapper {
    display: flex;
    flex-direction: column;
  }
  &--heading {
    background: #ffffff;
    border-radius: 5px 5px 0px 0px;
    display: flex;
    justify-content: space-between;
    padding: 12px 10px;
    align-items: center;
    &--title {
      h1 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #666666;
        margin-bottom: 0;
      }
    }
    &-btn {
      button {
        background: #000000;
        border-radius: 5px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        cursor: pointer;
        span {
          margin-right: 10px;
        }
      }
    }
  }
  &--event {
    width: 68%;
    height: 330px;
    border: 0.5px solid #dfdfdf;
    border-radius: 10px;
    background-color: #ffffff;
    margin: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    &-header {
      width: 100%;
      padding: 10px 0px;
      h1 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: #000000;
      }
    }
    &-info {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 8%;
      align-items: flex-start;
      &_user {
        width: 35%;
        display: flex;
        flex-direction: column;
        h1 {
          color: #000000;
          font-family: "Poppins";
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }
        &-detail {
          display: flex;
          flex-direction: column;
          &-list {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            p {
              width: 50%;
              font-family: "Poppins";
              font-size: 16px;
              font-style: normal;
              line-height: 24px;
              font-weight: 400;
              color: #666666;
              padding: 5px 0px;
            }
            .user_name {
              font-weight: 500;
              color: #222222;
              white-space: pre-wrap !important;
            }
          }
        }
      }
      &_action {
        width: 65%;
        display: flex;
        flex-direction: column;
        h1 {
          color: #000000;
          font-family: "Poppins";
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }
        &-detail {
          display: flex;
          flex-direction: column;
          &-list {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            p {
              width: 50%;
              font-family: "Poppins";
              font-size: 16px;
              font-style: normal;
              line-height: 24px;
              font-weight: 500;
              color: #222222;
              padding: 5px 0px;
            }
            .action_name {
              width: 23%;
              font-weight: 400;
              color: #666666;
            }
            .event_module {
              color: #4895ef;
            }
          }
        }
      }
      &_map {
        width: 27%;
        border: 0.5px solid #dfdfdf;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        h1 {
          color: #000000;
          font-family: "Poppins";
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          border-bottom: 0.5px solid #dfdfdf;
          padding: 10px 15px;
        }
        &-image {
          width: 100%;
          height: 165px;
          padding: 10px 15px 15px 15px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
