.legend{
    &_box{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;
        .a{
            display: flex;
            align-items: center;
            gap: 6px;
            .pub{
            height: 10px;
            width: 10px;
            background-color: #0f83ab;
            }
            h4{
            color: #000;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            margin: 0;
            // line-height: 16px; 
            }
        }
        .b{
          display: flex;
            align-items: center;
            gap: 6px;
           .dra{
               height: 10px;
            width: 10px;
            background-color: #faad14;
           }
              h4{
            color: #000;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            margin: 0;
            }
        }
          .c{
              display: flex;
            align-items: center;
            gap: 6px;
            .rev{
                   height: 10px;
            width: 10px;
            background-color: #ff7875; 
            }
              h4{
            color: #000;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            margin: 0;
            }
        }
          .d{
              display: flex;
            align-items: center;
            gap: 6px;
            .com{
                   height: 10px;
            width: 10px;
            background-color: rgb(15, 131, 171);
            }
              h4{
            color: #000;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            margin: 0;
            }
        }
         .e{
              display: flex;
            align-items: center;
            gap: 6px;
            .cli{
                   height: 10px;
            width: 10px;
            background-color: rgb(162, 217, 37);
            }
              h4{
            color: #000;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            margin: 0;
            }
        }
         .f{
              display: flex;
            align-items: center;
            gap: 6px;
            .cl{
                   height: 10px;
            width: 10px;
            background-color: rgb(222, 207, 63);
            }
              h4{
            color: #000;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            margin: 0;
            }
        }
    }
}

