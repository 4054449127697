.login {
  background-image: url("../assets/background.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
  height: 100vh;
  position: relative;
  overflow-y: hidden;
  &__wrapper {
    &--heading {
      padding: 16px 16px;
      h1 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: #000000;
      }
      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #666666;
      }
    }
    &--bottom {
      .btl {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #8c8c8c;
      }
    }
    &-boxs {
      width: 400px;
      position: absolute;
      top: 2%;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      form {
        background-color: #ffffff;
        border-radius: 10px;
      }
    }
    &-imgcontainer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 24px 0px;
      background: #1c1c1c;
      border-radius: 5px 5px 0px 0px;
    }
    &-container {
      padding: 16px;
      .top,
      .bottom {
        margin-top: 3%;
      }
      label {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #666666;
      }
      .me {
        font-family: "Poppins";
        font-style: italic;
        font-weight: 300;
        font-size: 18px;
        line-height: 27px;
        color: #666666;
      }
      input[type="email"],
      input[type="password"],
      input[type="text"],
      input[type="number"] {
        width: 100%;
        padding: 12px 20px;
        margin: 4px 0;
        display: inline-block;
        border: 1px solid #fafafa;
        box-sizing: border-box;
        background: #fafafa;
        border-radius: 5px;
        -moz-appearance: textfield;
        appearance: textfield;
        &:focus {
          outline: none;
        }
        &::placeholder {
          font-family: "Poppins";
          font-style: italic;
          font-weight: 300;
          font-size: 18px;
          line-height: 27px;
          color: #dfdfdf;
        }
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .psw {
        float: right;
        font-family: "Poppins";
        font-style: italic;
        font-weight: 300;
        font-size: 18px;
        line-height: 27px;
        color: #666666;
      }
      button {
        background-color: #4895ef;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #ffffff;
        padding: 14px 20px;
        border-radius: 5px;
        margin: 8px 0;
        border: none;
        cursor: pointer;
        width: 100%;
        &:hover {
          opacity: 0.8;
        }
      }
      .cancelbtn {
        width: auto;
        padding: 10px 18px;
        background-color: #f44336;
      }
      &--checkbox {
        padding: 20px 0;
      }
    }
    &--bottom {
      &-footer {
        display: flex;
        justify-content: center;
        p {
          font-family: "Poppins";
          font-style: italic;
          font-weight: 300;
          font-size: 18px;
          line-height: 27px;
          color: #000000;
          // span{
          //     background: #666666;
          //     color: #fff;
          //     border-radius: 100%;
          // }
        }
      }
    }
  }
}
