.subscription {
  margin: 2% 2%;
  overflow: auto;
  &__top {
    display: flex;
    justify-content: flex-start;
    margin: 1% 0;
    background-color: #ffffff;
    &-header {
      h1 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: #666666;
        margin: 0;
        padding: 20px;
      }
    }
  }
  &__middle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1% 0;
    &-left {
      display: flex;
      align-items: center;
      .btn-div {
        margin: 0 16px;
        button {
          background: #4895ef;
          border: 0.3px solid #4895ef;
          box-sizing: border-box;
          border-radius: 5px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 27px;
          color: #ffffff;
          padding: 8px 20px;
        }
      }
      .show {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        color: #9b9b9b;
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          position: relative;
          background: #666666;
          border: 1px solid #8c8c8c;
          box-sizing: border-box;
          border-radius: 5px;
          color: #fff;
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
      }
    }
  }
  &__tablename {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #666666;
    margin: 0;
    white-space: nowrap;
  }
  &__header {
    background: #ffffff;
    border-radius: 5px 5px 0px 0px;
    &-children {
      display: flex;
      justify-content: space-between;
      padding: 12px 10px;
      h1 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #666666;
      }
      button {
        background: #000000;
        border-radius: 5px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        cursor: pointer;
        svg {
          margin-right: 10px;
        }
      }
    }
  }
  &__field {
    &-form {
      .field-one {
        margin-top: 2%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 10px;
        h1 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #666666;
          margin: 0;
        }
        button {
          background: #000000;
          border-radius: 5px;
          &:focus {
            outline: none;
          }
          &::placeholder {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 27px;
            color: #dfdfdf;
          }
        }
      }
    }
    .field-two {
      display: flex;
      justify-content: space-between;
      .first,
      .second {
        margin-top: 2%;
        min-width: 518px;
        label {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #666666;
        }
        input[type="date"],
        input[type="password"] {
          width: 100%;
          padding: 12px 20px;
          margin: 8px 0;
          display: inline-block;
          background: #fafafa;
          border: 0.3px solid #666666;
          box-sizing: border-box;
          border-radius: 5px;
          &:focus {
            outline: none;
          }
          &::placeholder {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 27px;
            color: #dfdfdf;
          }
        }
        .list {
          width: 100%;
          padding: 12px 20px;
          margin: 8px 0;
          display: inline-block;
          background: #fafafa;
          border: 0.3px solid #666666;
          box-sizing: border-box;
          border-radius: 5px;
          &:focus {
            outline: none;
          }
          &::placeholder {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 27px;
            color: #dfdfdf;
          }
        }
        .datalist {
          position: absolute;
          background-color: white;
          border: 1px solid blue;
          border-radius: 0 0 5px 5px;
          border-top: none;
          font-family: sans-serif;
          width: 350px;
          padding: 5px;
          max-height: 10rem;
          overflow-y: auto;
          option {
            font-family: "Poppins";
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 27px;
            color: #aeaeae;
          }
        }
      }
    }

    .btn {
      width: 200px;
      margin: auto;
      button {
        background-color: #4895ef;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #ffffff;
        padding: 14px 20px;
        border-radius: 5px;
        margin: 8px 0;
        border: none;
        cursor: pointer;
        width: 100%;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

.sub-del {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #666666;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  box-shadow: none;
  justify-content: left;
  width: fit-content;
  // svg {
  //   margin-right: 10px;
  // }
}

.sub-dele {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #fff;
  border: none;
  background: red;
  display: flex;
  align-items: center;
  box-shadow: none;
  justify-content: flex-start;
  width: fit-content;
  // svg {
  //   margin-right: 10px;
  // }
}
